.c-vote {
	@mixin text-style-mu;
	padding:var(--spacing) var(--spacing-s);
	text-align:center;
}

.c-vote__feedback,
.c-vote__success,
.c-vote__failed {
	display:none;
}

.c-vote__text {
	padding-bottom:var(--spacing-sm);
	display:inline-block;
}