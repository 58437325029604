/* =============================================================================
 * Tabs
 * TODO:Hover effects
============================================================================= */
.c-tabs {
	position:relative;
	overflow:visible;
}

.c-tabs::after {
	left:0;
	content:"";
	display:block;
	position:absolute;
	z-index:-1;
	right:0; /* Left is set in theme-one.css and theme-two.css */
	height:1px;
	background:var(--color-light-grey-green);
}

.c-tabs__list {
	display:-ms-flexbox;
	display:flex;
	align-items:flex-end;
}

#tab-content {
	transition:var(--transition);
}

.c-tabs__item {
	transition:var(--transition);
	text-align:center;
	@mixin text-style-reset;
	border-bottom:3px solid transparent;
}

.c-tabs__item + .c-tabs__item {
	margin-left:var(--spacing-ml);
}

.c-tabs__item:hover {
	background-color:var(--color-light-grey-green) lightness(95%);
}

.c-tabs__item--active {
	border-bottom:3px solid var(--color-turtle-green);
}

.c-tabs__item--active:hover .c-tabs__title {
	text-decoration:none;
}

.c-tabs__item--active .c-tabs__title,
.c-tabs__item--active .c-tabs__title:visited,
.c-tabs__item--active .c-tabs__title:hover {
	color:var(--color-grass);
}

.c-tabs__title,
.c-tabs__title:visited {
	padding:var(--spacing) 0;
	color:var(--color-black);
	display:block;
	font-weight:bold;
	text-align:center;
	transition:var(--transition);
}

.c-tabs__title:hover {
	color:color(var(--color-grass) blackness(45%));
}

.c-tabs .c-card__content {
	padding-top:1.5em;
	padding-bottom:2em;
}

.tab-chapters-mobile {
	display:none;
}

@media(--below-md) {
	.tab-chapters {
		position:-webkit-sticky;
		position:sticky;
		top:0;
		z-index:1;
	}

	.tab-chapters .c-card {
		background:transparent;
		box-shadow:none;
	}

	.tab-chapters .c-card__content {
		padding-left:0;
		padding-right:0;
	}

	.tab-chapters-mobile {
		display:block;
	}

	.tab-chapters-desktop {
		display:none;
	}

	.jsTabContent .anchor {
		display:block;
		position:relative;
		top:-80px;
		visibility:hidden;
	}
}
