.c-source {
	background:var(--color-light-grey);
	padding:var(--spacing-m) var(--spacing-sm) var(--spacing-sm);
	position:relative;
	height:100%;
}

.c-source__tag {
	font-size:12px;
	position:absolute;
	top:0;
	right:0;
	background:var(--color-prussian-blue);
	color:var(--color-white);
	font-weight:700;
	border-bottom-left-radius:0px;
	padding:var(--spacing-xs);
}

.c-source__title {
	margin-bottom:var(--spacing-sm);
	@mixin text-style-reset;
	font-weight:700;
}

.c-source p {
	margin-bottom:var(--spacing-sm);
}

fieldset > .c-source {
	margin:0 -var(--spacing-sm);
}