.c-delete-button {
	color:var(--color-battleship-grey);
	transition:var(--transition);
	display:-ms-flexbox;
	display:flex;
	-ms-flex-align:center;
	align-items:center;
	-ms-flex-pack:end;
	justify-content:flex-end;
	text-decoration:none;
	margin-top:var(--spacing-xs);
}

.c-delete-button:hover,
.c-delete-button:focus {
	color:color(var(--color-dusty-orange) blackness(25%));
}

.c-delete-button__text {
	min-width:52px;
	margin-left:var(--spacing-xs);
	white-space:nowrap;
}

@media(--md) {
	.c-delete-button__text {
		margin-left:var(--spacing);
		min-width:59px;
	}
}
