.c-list-component {
	display:table;
	width:100%;
	border-collapse:separate;
	border-spacing: 0 var(--spacing);
}

@media(--md) {
	.c-list-component--competence-cards tr td:nth-child(1) {
		width: 40%
	}

	.c-list-component--competence-cards tr td:nth-child(2) {
		width: 25%;
	}

	.c-list-component--competence-cards tr td:nth-child(3) {
		width: 300px;
	}

	.c-list-component--competence-cards tr td:last-child {
		width: 120px;
	}
}

@media(--s) {
	.c-list-component {
		display:block;
	}
}