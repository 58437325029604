.c-page-footer {
	border-top:1px solid #c6e4b9;
}

.c-page-footer__timestamp {
	font-size:0.882em;
	color:var(--color-cold-grey);
}

.c-page-footer__timestamp-date {
	font-style:italic;
	font-family:var(--type-family-secondary);
}