.c-filter {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
}

.c-filter__item {
	background-color:var(--color-white);
	border:1px solid var(--color-light-grey-green);
	color:var(--color-grass);
	margin:0 18px 18px 0;
	padding:6px 18px;
	@mixin text-style-epsilon;
}

.c-filter__item:hover {
	cursor:pointer;
}

.c-filter__item:hover {
	background-color:var(--color-grass);
	border-color:var(--color-white);
	color:var(--color-white);
	transition:var(--transition);
}

.c-filter__item span {
	margin-left:6px;
}

.c-filter__item--selected,
.c-filter__item--selected {
	background-color:var(--color-grass);
	border-color:var(--color-white);
	color:var(--color-white);
}
