.c-product-list__topic-heading {
	font-size:21px;
}

.c-product-list__topic-heading::after {
	display:block;
	width:42px;
	height:1px;
	margin:9px 0 18px;
	background-color:rgb(108, 192, 74);
	content:"";
}

.c-product-list__price {
	margin:10px 0;
}

.c-product-list__price-details {
	margin-bottom:0.2em;
	font-family:"Freight", serif;
	font-size:1.059em;
	font-weight:500;
	font-style:italic;
	line-height:1.33;
	color:#757575;
}

.product-list__button {
	position:absolute;
	bottom:40px;
	left:50%;
	margin-left:-100px;
	width:200px;
}