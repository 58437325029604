.c-header__inner {
  background: var(--color-grass);
}

.c-header__inner::after {
  background-color: color(var(--color-grass) alpha(30%));
}

.c-header__profile-icon .jsPopInActive {
  background: var(--color-light-grey-green);
}

@media (--sm) {
  .c-header__inner .jsPopIn {
    max-width: 200px;
  }
}

@media (--below-md) {
  .c-header__inner {
    border-top: 1px solid var(--color-light-grey-green);
    border-bottom: 1px solid var(--color-light-grey-green);
  }
}

@media (--md) {
  .c-header__inner {
    background: var(--color-main-background);
  }
}
