.c-toggle-content__selected,
.c-toggle-content__toggle:checked ~ .c-toggle-content__inverted {
	display:none;
	overflow:hidden;
}

.c-toggle-content__toggle:checked ~ .c-toggle-content__selected,
.c-toggle-content__inverted {
	display:block;
	margin-left:12px;
	margin-top:12px;
	overflow:visible;
}

.c-toggle-content__inverted--inner,
.c-toggle-content__toggle:checked ~ .c-toggle-content__selected--inner {
	margin-left:0;
}

.c-toggle-content__inverted {
        margin-left:0;
}

@media(--md) {
	.c-toggle-content__custom .c-toggle-content__toggle:checked ~ .c-toggle-content__selected {
		margin-left:0;
	}

	.c-toggle-content__inverted,
	.c-toggle-content__toggle:checked ~ .c-toggle-content__selected {
		margin-left:48px;
		margin-top:24px;
	}
    
    .c-toggle-content__inverted {
        margin-left:0;
    }
}
