:root {
   --modal-transition-duration: 0.2s;
}


.modal {
  position: fixed;
  z-index: var(--z-index-overlay);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.modal:not(.modal--is-visible) {
  pointer-events: none;
  background-color: transparent;
}

.modal--is-visible {
  opacity: 1;
  visibility: visible;
}

.modal__inner {
  width: 100%;
  max-width: 820px;
  max-height: 100%;
  overflow: auto;
  background: #fff;
  @mixin gradient-box;
}

.modal__header {
  padding: var(--spacing);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal__title {
    @mixin text-style-beta;
}

.modal__content {
  padding: var(--spacing);
}

.modal__footer {
  padding: var(--spacing);
}

.modal__close-btn {
  display: flex;
  flex-shrink: 0;
  border-radius: 50%;
  transition: 0.2s;
  border: 0 none;
  appearance: none;
}

.modal__icon {
  --size: 24px;
  height: var(--size);
  width: var(--size);
  display: inline-block;
  color: inherit;
  fill: currentColor;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}

.modal__icon--small {
  --size: 16px
}

.modal__close-btn .modal__icon {
  display: block;
  margin: auto;
}

.modal__close-btn--outer {
  display: none;
  width: 48px;
  height: 48px;
  position: fixed;
  top: var(--spacing);
  right: var(--spacing);
  z-index: var(--z-index-fixed-element, 10);
  background-color: rgba(0, 0, 0, 0.9);
  transition: 0.2s;
}

.modal__close-btn--outer .modal__icon {
  color: #fff;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.modal__close-btn--outer:hover {
  background-color: rgba(0, 0, 0, 1);
}
.modal__close-btn--outer:hover .modal__icon {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.modal__close-btn--inner {
  display: flex;
  width: 2em;
  height: 2em;
  background-color: #fafafa;
  transition: 0.2s;
}

.modal__close-btn--inner .modal__icon {
  color: inherit;
}

.modal__close-btn--inner:hover {
  background-color: #fff;
}

@media (prefers-reduced-motion: no-preference) {
  .modal--animate-fade {
    --modal-transition-duration: 0.2s;
    transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s var(--modal-transition-duration);
  }

  .modal--animate-fade.modal--is-visible {
    transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s;
  }
}

.modal--is-loading .modal__inner {
  visibility: hidden;
}

.modal--is-loading .modal__loader {
  display: flex;
}

.modal__loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: none;
  pointer-events: none;
}

@media screen and (min-width: 992px) {

  .modal__header,
  .modal__content,
  .modal__footer {
    padding-left: var(--spacing-sm);
    padding-right: var(--spacing-sm);
  }

  .modal__close-btn--inner {
    display: none;
  }

  .modal__close-btn--outer {
    display: flex;
  }

}