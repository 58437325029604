/* =============================================================================
 * Space between
============================================================================= */
.o-space-between {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-pack:justify;
	justify-content:space-between;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	-ms-flex-align:center;
	align-items:center;
}

.o-space-between__left {
	margin-right:15px;
}

.o-space-between.o-space-between--align-top {
	-ms-flex-align:start;
	align-items:flex-start;
}

.o-space-between.o-space-between--nowrap {
	-ms-flex-wrap:nowrap;
	flex-wrap:nowrap;
}

.o-space-between__right {
	margin-left:auto;
}
