.c-company-card{
	display: flex;
	height: 100%;
}
.c-company-card__image {
	display:block;
	height:100%;
	max-width: 33%;
	width: auto;
}
.c-company-card__image picture img{
	width: 100%;
	height: auto; 
}
.c-company-card__image picture{
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	width: 100%;
	height: 100%; 
}

.c-company-card__content{
	margin:0 auto;
	padding:1rem 0;
	display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.c-company-card__header:not(:last-child) {
	margin-bottom:0.706em;
}
.c-company-card .c-company-card__content__label{
	display:block;
}
.c-company-card .c-company-card__content__member-number, .c-company-card .c-company-card__content__label{
	display:block;
	text-align: center;
	padding-bottom: 0.5rem;
	font-size: 1.2rem;
}
.c-company-card .c-company-card__content__link{ 
	display: block;
}
