.c-control__group {
	margin-bottom:48px;
}

/* TODO: Make a better solution for this :( */
.c-controls > div:last-child > .c-control__group {
	margin-bottom:0;
}

.c-control__points-wrap {
	margin-bottom:var(--spacing-m);
}

.c-control__point[class] {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:row;
	flex-direction:row;
	font-size:14px;
	-ms-flex-pack:justify;
	justify-content:space-between;
	margin-bottom:var(--spacing-s);
	-ms-flex-align:start;
	align-items:flex-start;
}

.c-control__point-text {
	max-width:90%;
}

.c-control__point-text.c-control__point-text--removed {
	text-decoration:line-through;
}

.c-control__title {
	font-weight:700;
	margin-bottom:var(--spacing);
}

@media(--md) {
	.c-control__add-item {
		width:75%;
	}

	.c-control__title {
		margin-bottom:var(--spacing-sm);
	}

	.c-control__point[class] {
		font-size:16px;
	}
}

@media(--lg) {
	.c-control__add-item {
		width:50%;
	}
}
