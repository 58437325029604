.c-article-teaser {
	-ms-flex-direction:column;
	flex-direction:column;
	height:100%;
	@media(--md) {
		text-align:center;
	}
}

.c-article-teaser > picture,
.c-article-teaser__img {
	width:100%;
}

.c-article-teaser__content {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	flex-direction:column;
	height:100%;
}

.c-article-teaser__heading,
.c-article-teaser__heading > a {
	color:var(--color-black);
}

.c-article-teaser__heading {
	margin-bottom:0.54em;
	font-weight:bold;
	font-size:1.294em;
	line-height:1.2;
}

.c-article-teaser__heading > a {
	border-bottom:1px solid rgba(0, 0, 0, 0.15);
}

.c-article-teaser__heading > a:hover {
	border-bottom:1px solid rgb(116, 117, 117);
	text-decoration:none;
}

.c-article-teaser__footer {
	margin-top:0.7em;
}

.c-article-teaser__link {
	font-style:italic;
	font-family:var(--type-family-secondary);
	font-size:1.059em;
}