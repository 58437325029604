.c-network-cable-calculator .c-dropdown-variations:after {
	background-color:transparent;
}
.c-network-cable-calculator__indication .c-rect-input__input[readonly] {
	background-color: #f3110054;
}
.c-network-cable-calculator__indication .c-rect-input__input[type=number][readonly]::-webkit-inner-spin-button,
.c-network-cable-calculator__indication .c-rect-input__input[type=number][readonly]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.c-network-cable-calculator__error {
	color:rgb(237, 103, 37);
	font-style:italic;
}
.c-network-cable-calculator__extra--label {
	font-size: 13px;
	color: rgb(117, 117, 117);
}
.c-network-cable-calculator .c-dropdown-variations.c-form__item {
	margin-bottom: 0;
}
