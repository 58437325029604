.ql-snow .ql-picker.ql-header .ql-picker-item::before,
.ql-snow .ql-picker.ql-header .ql-picker-label::before {
    content: 'Text' !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before, 
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before {
    content: 'Rubrik 2' !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before, 
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before {
    content: 'Rubrik 3' !important;
}

.ql-snow .ql-tooltip[data-mode=link]::before {
    content: "Ange länk:" !important;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    content: 'Spara' !important;
}