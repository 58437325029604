.c-accordion {
	clear: both;
}

.c-accordion__fold {
	@mixin gradient-box;
	margin-bottom:var(--spacing);
}

.c-accordion__fold:last-of-type {
	margin-bottom:0px;
}

.c-accordion__label {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-pack:justify;
	justify-content:space-between;
	-ms-flex-align:center;
	align-items:center;
	padding:24px;
	cursor:pointer;
	transition:var(--transition);
}

.c-accordion__locked-label {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-align:center;
	align-items:center;
	padding:24px 12px;
}

.c-accordion__locked-title {
	color:var(--color-black);
	display:block;
	font-weight:700;
	padding-left:12px;
}

.c-accordion__title {
	display:block;
	font-weight:700;
	padding-right:12px;
}

.c-accordion__title--fullWidth {
	width: 100%;
}

.c-accordion__header {
	text-align:right;
	margin-bottom:12px;
}

.c-accordion__header__action {
	padding-bottom:12px;
}

.c-accordion__heading {
	text-align:left;
	margin-bottom:12px;
	font-size: 22px;
	font-weight: 700;
	padding-left: 24px;
	padding-top: 18px;
}

.c-accordion__expander,
.c-accordion__expander--expanded {
	display:inline-block;
	width:24px;
	height:24px;
	border:0;
	background-color:transparent;
	vertical-align:middle;
	transition:var(--transition);
	cursor: pointer;
}

.c-accordion__expander:focus {
	outline: none;
}

.c-accordion__expander--expanded {
	-ms-transform:rotate(180deg);
	transform:rotate(180deg);
}

.c-accordion__content {
	padding:12px 24px 49px;
}

.c-accordion__fold .c-table-list {
	background:var(--color-white);
}

.c-accordion__fold .c-table-list__item {
	padding:var(--spacing-s);
}

.c-accordion__fold .c-card__content {
	padding:0;
}

/* Remove gradient box from accordion content children */
.c-accordion__content > *[class] {
	@mixin reset-gradient-box;
}

@media print {
	.c-accordion__title {
		margin-left:0;
	}

	.c-accordion__expander {
		display:none;
	}

	.c-accordion__label {
		padding:0;
	}

	.c-accordion__content {
		display:block !important;
		padding-left:0;
	}
}
