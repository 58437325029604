.c-hero-image {
	position:relative;
	min-height: 360px;
	height:360px;
	color:#fff;
	@media(--below-lg) {
		min-height: 300px;
		height:300px;
	}
}

.c-hero-image.c-hero-image--no-content {
	height:240px;
	@media(--below-lg) {
		height:180px;
	}
}

.c-hero-image__container {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	margin:auto;
	@mixin width-limiter;
}

.c-hero-image__buttons {
	position: absolute;
	bottom: 25px;
}


.c-hero-image__content {
	@media(--lg) {
		width:50%;
	}
	@media(--below-lg) {
		width:80%;
	}
	@media(--below-md) {
		width:100%;
	}
}

.c-hero-image__heading {
	margin-bottom:0.636em;
	font-weight:bold;
	font-size:1.5em;
	line-height:1.1;
	text-shadow:0 0 36px rgba(0, 0, 0, 0.3);
	@media(--sm) {
		font-size:2em;
	}
	@media(--md) {
		font-size:2.588em;
	}
}

.c-hero-image__content p {
	text-shadow:0 0 36px rgba(0, 0, 0);
	@media(--md) {
		font-size:1.3em;
		font-weight:bold;
	}
}
