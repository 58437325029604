.c-editorial-asset--right {
	padding-left:20px;
	border-left:1px solid rgba(0, 0, 0, 0.1);
}

.c-editorial-asset--left {
	padding-right:20px;
	border-right:1px solid rgba(0, 0, 0, 0.1);
}

.c-editorial-asset--blockquote {
	color:var(--color-grass);
	font-family:var(--type-family-secondary);
	font-size:28px;
	line-height:1.1;
}

.c-editorial-asset--external::after {
	content:"";
	background:url(data:image/svg+xml,%3Csvg%20fill%3D%22%234BA925%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cg%20id%3D%22icon-up-24%22%20transform%3D%22rotate%2890%2011.651%207.805%29%22%3E%3Cpath%20d%3D%22M4.6%2010L6%2011.4l4-4V13a6%206%200%200%200%206%206h3v-2h-3a4%204%200%200%201-4-4V7.4l4%204%201.4-1.4-5.7-5.7a1%201%200%200%200-1.4%200L4.6%2010z%22%20id%3D%22Shape%22/%3E%3C/g%3E%3C/svg%3E);
	background-repeat:no-repeat;
	display:inline-block;
	width:16px;
	height:14px;
	margin:0 0.2em;
	background-position:center;
}

@media(--xl) {
	.c-editorial-asset--right,
	.c-editorial-asset--left {
		width:320px;
	}

	.c-editorial-asset--right {
		margin-right:calc(-50% - 20px);
		float:right;
	}

	.c-editorial-asset--left {
		margin-left:calc(-50% - 20px);
		float:left;
	}
}