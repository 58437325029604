.c-label {
	background-color:var(--color-prussian-blue);
	color:var(--color-white);
	display:inline-block;
	font-weight:700;
	font-size:0;
}

.c-label__logo {
	display:inline-block;
	vertical-align:middle;
	padding:0 5px 1px 5px;
}

.c-label__text {
	font-size:17px;
	display:inline-block;
	vertical-align:middle;
	border-left:1px solid;
	padding:0 4px;
}

.c-label__info {
	color:#fff;
	display:inline-block;
	padding:0.25em 0.25em;
	font-size:50%;
	line-height:inherit;
	margin:0 0.5rem 0.25rem;
	vertical-align:middle;
	white-space:nowrap;
}

.c-label__info.c-label__info--warning {
	background-color:var(--color-dusty-orange);
}
