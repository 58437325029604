.c-product-media {
	text-align:center;
}

@media(min-width:1081px) {
	.c-product-infobox--right {
		-ms-flex-order:1;
		order:1;
	}

	.c-product-media--left {
		text-align:left;
	}

	.c-product-media--right {
		text-align:right;
	}
}

.c-product {
	-ms-flex:1;
	flex:1;
	-ms-flex:1 1 auto;
	flex:1 1 auto;
	-webkit-flex:1;
	position:relative;
}

.c-product__content-with-button {
	padding:40px 20px 100px !important;
}

.c-product__content-with-button {
	padding: 40px 20px 100px !important;
}

.c-product__content {
	padding: 40px 20px 20px;
	background-color: #ffffff;
	position:relative;
}

.c-product__content li {
	padding-top:10px;
}

.c-product__content .c-button--round {
	box-shadow:0 0 0 2px var(--color-light-grey-green) inset !important;
	width:2.352em;
	height:2.352em;
}

.c-product__content .c-button .c-button__icon {
	width:1.235em;
	height:1.235em;
}

.c-product hr {
	background-color:rgb(213, 214, 210);
	margin:1.5em 0;
}

.c-product__heading {
	background-color:rgb(75, 169, 37);
	color:#fff;
	font-weight:bold;
	padding:24px 12px;
	text-align:center;
}

.c-product__topic {
	font-weight:bold;
	font-size:0.706em;
	line-height:2;
	letter-spacing:1.5px;
	text-transform:uppercase;
	color:rgb(255, 255, 255);
}

.c-product__topic + h3 {
	font-size:38px;
}

.c-product--disable {
	color:rgb(213, 214, 210);
}

.c-product--disable .c-button {
	visibility:hidden;
}

.c-product__price {
	margin:10px 0;
}

.c-product__price del {
	margin-right:0.5em;
	color:#767676;
	font-weight:normal;
	font-size:0.9em;
}

.c-product__price-details {
	margin-bottom:0.2em;
	font-family:"Freight", serif;
	font-size:1.059em;
	font-weight:500;
	font-style:italic;
	line-height:1.33;
	color:#757575;
}

.product-list__button {
	padding:12px 48px;
}

.c-product__footer {
	padding-top:10px;
	color:#666;
	font-size:14px;
	text-align:center;
}