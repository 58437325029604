.c-add-button[class],
.c-add-button[class]:visited {
	color:var(--color-grass);
	transition:var(--transition);
	display:-ms-flexbox;
	display:flex;
	-ms-flex-align:center;
	align-items:center;
	text-decoration:none;
}

.c-add-button[class]:hover,
.c-add-button[class]:focus {
	color:color(var(--color-grass) blackness(50%));
}

.c-add-button__text {
	margin-left:6px;
	white-space:nowrap;
}

@media(--md) {
	.c-add-button__text {
		margin-left:12px;
	}
}
