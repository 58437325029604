.c-guide-step {
	display:-ms-flexbox;
	display:flex;
	margin-bottom:var(--spacing-m);
	max-width:450px;
	-ms-flex-direction:column;
	flex-direction:column;
}

.c-guide-step__content {
	margin-left:0;
	margin-top:var(--spacing);
}

@media(--sm) {
	.c-guide-step {
		-ms-flex-direction:row;
		flex-direction:row;
	}

	.c-guide-step__content {
		margin-left:var(--spacing-sm);
		margin-top:0;
	}
}

.c-guide-step__title {
	margin-bottom:var(--spacing);
}

.c-guide-step__title a {
	@mixin text-style-gamma;
	color:var(--color-prussian-blue);
	text-decoration:underline;
}

.c-guide-step__title a:hover {
	color:var(--color-grass);
}

.c-guide-step__description {
	margin-bottom:var(--spacing);
}

.c-guide-step__icon,
.c-guide-step__title,
.c-guide-step__description {
	transition:color 0.3s ease;
}

.c-guide-step--disabled .c-guide-step__icon,
.c-guide-step--disabled .c-guide-step__title a,
.c-guide-step--disabled .c-guide-step__description {
	color:var(--color-light-grey);
}

/*
    Neccessary since SU has implemented the Accordion compontent 
	with .s-text wrapping the content in every case
 */
.s-text .c-guide-step__title,
.s-text .c-guide-step__description {
	margin-bottom:var(--spacing);
}