.c-news-item {
	margin-bottom:2.824em;
}

.c-news-item__img {
	display:block;
	margin-bottom:2em;
	width:100%;
}

.c-news-item__timestamp {
	display:inline-block;
	margin-bottom:0.2em;
	font-family:var(--type-family-secondary);
	font-size:1.059em;
	font-weight:500;
	font-style:italic;
	line-height:1.33;
	color:#757575;
}

.c-news-item__heading,
.c-news-item__heading > a {
	color:var(--color-black);
}

.c-news-item__heading {
	font-weight:bold;
	font-size:1.294em;
	line-height:1.2;
}

.c-news-item__heading > a {
	border-bottom:1px solid rgba(0, 0, 0, 0.15);
}

.c-news-item__heading > a:hover {
	border-bottom:1px solid rgb(116, 117, 117);
	text-decoration:none;
}

.c-news-item__body {
	line-height:1.41;
}

.c-news-item__body + *,
* + .c-news-item__body {
	margin-top:0.5em;
}

.c-news-item__body > p {
	display:inline;
	vertical-align:middle;
}

.c-news-item__category {
	display:inline;
	font-weight:bold;
	font-size:12px;
	text-transform:uppercase;
	line-height:2;
	letter-spacing:1.5px;
	color:var(--color-grass);
}