/* =============================================================================
 * Mobile Navigation
============================================================================= */
.c-mobile-megamenu {
	background-color:var(--color-grass);
	color:var(--color-white);
	font-size:17px;
}

.c-mobile-megamenu.c-mobile-megamenu--active {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	flex-direction:column;
	-ms-flex-pack:justify;
	justify-content:space-between;
	width:100%;
	/*height:calc(100vh - 137px);
	overflow:auto;*/
	-webkit-overflow-scrolling:touch;
	/*padding-top:var(--spacing);*/
	z-index:99;
}

.c-mobile-megamenu__list {
	width:100%;
}

.c-mobile-megamenu__link {
	color:var(--color-white);
	border-bottom:1px solid var(--color-light-grey-green);
	display:block;
	padding:10px var(--spacing-m) 10px var(--spacing-sm);
	word-break:break-all;
}

/*.c-mobile-megamenu__item--go-back .c-mobile-megamenu__link {
	border-bottom:none;
	margin-bottom:var(--spacing-sm);
}*/

.c-mobile-megamenu__header-link {
	@mixin text-style-gamma;
}

.c-mobile-megamenu__item--selected a {
	font-weight:700;
}

.c-mobile-megamenu__item--selected a::before {
	content:"•";
	margin-right:0.5em;
}

/* Icons 
----------------------------------------------------------------------------- */

.c-icon--back {
	margin-right:0.5em;
}

.c-mobile-megamenu__item--has-children .c-icon--has-children {
	display:inline-block;
	position:absolute;
	right:20px;
}

/* Navigation footer
----------------------------------------------------------------------------- */
.c-mobile-megamenu__footer {
	margin-top:var(--spacing-s);
}

.c-mobile-megamenu__footer-link {
	color:var(--color-white);
	display:block;
	padding:0 var(--spacing-sm);
	margin:var(--spacing) 0;
}

/*Increase margin in order to make last link item clickable when safari bar is shown*/
.c-mobile-megamenu__footer-link:last-child {
	margin-bottom:var(--spacing-ml);
}

/* Slide menu plugin styles
----------------------------------------------------------------------------- */
.slide-menu {
	transition:transform 300ms ease-in-out;
	-ms-transform:translateX(0%);
	transform:translateX(0%);
	z-index:1000;
}

.slide-menu .slider {
	width:100%;
	transition:transform 300ms ease-in-out;
	-ms-transform:translateX(0);
	transform:translateX(0);
}

.slider[data-current-list-level="0"] {
	-ms-transform:translateX(0);
	transform:translateX(0);
}

.slider[data-current-list-level="1"] {
	-ms-transform:translateX(-100%);
	transform:translateX(-100%);
}

.slider[data-current-list-level="2"] {
	-ms-transform:translateX(-200%);
	transform:translateX(-200%);
}

.slider[data-current-list-level="3"] {
	-ms-transform:translateX(-300%);
	transform:translateX(-300%);
}

.slider[data-current-list-level="4"] {
	-ms-transform:translateX(-400%);
	transform:translateX(-400%);
}

.slider[data-current-list-level="5"] {
	-ms-transform:translateX(-500%);
	transform:translateX(-500%);
}

.slide-menu ul {
	position:relative;
	width:100%;
	max-width:100%;
}

.slide-menu ul ul {
	position:absolute;
	top:0;
	left:100%;
	display:none;
}

.slide-menu .active {
	display:block;
}

.slide-menu-control {
	color:var(--color-white);
	display:block;
	/*margin-bottom:var(--spacing-sm);*/
	padding:var(--spacing) var(--spacing-sm);
}

/* Hide message and coockie bar when menu is shown */
body.taken .c-header .c-message {
	display:none !important;
}

body.taken .cookie-bar {
	display:none !important;
}

@media(--md) {
	.c-mobile-megamenu\@lg {
		display:inherit;
	}

	.c-mobile-megamenu\@sm {
		display:none;
	}
}

#menu {
	z-index:99; /* or we get overlap issues with search */
}