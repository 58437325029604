/* =============================================================================
 * Grey out
============================================================================= */

.o-grey-out{
    pointer-events: none;
    opacity: 0.4;
}
.o-grey-out ul, .o-grey-out li, .o-grey-out a{
    color: rgb(116, 117, 117) !important;
}