.c-image-container {
	font-size:8px;
	margin:0 -21px;
}

.c-image-container__image {
	width:100%;
}

@media(--md) {
	.c-image-container {
		margin-right:-56px;
		margin-left:-58px;
		font-size:20px;
	}
}

@media(--lg) {
	.c-image-container {
		margin-left:-61px;
		margin-right:-60px;
		font-size:21px;
	}
}

@media(--xl) {
	.c-image-container {
		margin-left:-89px;
		margin-right:-80px;
		font-size:30px;
	}
}
