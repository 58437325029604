.c-icon-link {
	display:-ms-inline-flexbox;
	display:inline-flex;
	-ms-flex-align:center;
	align-items:center;
}

.c-icon-link .c-icon-link__icon {
	width:1.412em;
	height:1.412em;
}

@media screen and (min-width: 641px)
{
	.c-icon-link.centered {
		margin-left: 25px;
		
	}
	.c-list-item.list-item-icon {
		padding: 0px;
		text-align: center;
	}

	.c-list-item.list-item-icon-competence {
		padding: 0px;
		text-align: center;
		max-width: 60px;
	}
}

.c-icon--link.hidden {
	visibility: hidden;
	margin:2px;
}

.c-icon-link.checkboxborder {
	border-style:solid;
	border-width:1px;
	border-color:lightgrey;
	margin-top:2px;
}



.c-icon-link__icon + .c-icon-link__text,
.c-icon-link__text + .c-icon-link__icon {
	margin-left:0.588em;
}

.c-icon-link__text,
.c-icon-link__type + * {
	padding-left:0.3em;
	@mixin text-style-reset;
	color:var(--color-grass);
}

.c-icon-link__text + *::before {
	content:" — ";
	padding-right:0.3em;
	padding-left:0.3em;
	color:#8b8;
}

.c-icon-link__type,
.c-icon-link__size {
	font-size:0.824em;
	color:#1e1e1e;
}

.c-icon-link__type {
	font-family:var(--type-family-secondary);
	text-transform:uppercase;
}