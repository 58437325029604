.c-spinner {
	position:fixed;
	z-index:9999;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background:rgba(0, 0, 0, 0.5);
}

.c-spinner::before {
	content:"";
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	width:40px;
	height:40px;
	border:2px solid var(--color-white-grey);
	border-top:2px solid var(--color-battleship-grey);
	border-radius:100%;
	margin:auto;
	animation:spin 0.8s infinite cubic-bezier(0.53, -0.31, 0.46, 1.31);
}

@keyframes spin {
	from {
		transform:rotate(0);
	}

	to {
		transform:rotate(360deg);
	}
}
