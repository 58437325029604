.c-placard {
	min-height:350px;
	height:100%;
	padding-top:72px;
}

.c-placard__image {
	display:block;
	margin-bottom:24px;
}

.c-placard__image img {
	width:100%;
}

.c-placard__text-area {
	padding:0 12px;
}

@media(--md) {
	.c-placard {
		padding-top:120px;
		min-height:750px;
		height:auto;
	}
}
