.c-entity-list {
	display: flex;
	flex-direction: column;
}

.c-entity-list > *:first-child {
	margin-top: 0;
}

.c-entity-section-title {
	font-weight: bold;
	font-size: 0.706em;
	line-height: 2;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: var(--color-greyish-brown);
	margin-bottom: 8px;
}

.c-entity-item + .c-entity-section-title {
	margin-top: 24px;
}

.c-entity-item:not(:last-child) {
	margin-bottom: 8px;
}

.c-entity-item {
	position: relative;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgb(0, 0, 0, 0.12);
	border-radius: 4px;
	padding: 15px;
	display: block;
	width: 100%;
	color: var(--color-black);
	display: flex;
	align-items: center;
	transition: box-shadow 200ms ease-in-out, color 200ms ease-in-out;
}

a.c-entity-item:hover {
	color: var(--color-black);
	box-shadow: 0px 1px 2px 0px rgb(0, 0, 0, 0.18);
}

a.c-entity-item:hover .c-entity-item__title,
a.c-entity-item:focus .c-entity-item__title {
	color: var(--color-grass);
}

.c-entity-item__heading {
	display: flex;
	align-items: center;
}

.c-entity-item__title {
	margin: 0;
	font-size: 18px;
	line-height: 1.2;
	margin-right: 6px;
	color: var(--color-black);
}

.c-entity-item__body {
	font-size: 14px;
	font-weight: 200;
	margin: 2px 0 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--color-prussian-blue);
}

.c-entity-item__media {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 52px;
	width: 52px;
	background-color: #e5ebed;
	padding: 0;
	margin-right: 12px;
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 52px;
}

.c-entity-item__media img {
	width: 100%;
	height: auto;
	object-fit: cover;
}
