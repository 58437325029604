@define-mixin that-or-that $selector, $suffix {
	$(selector)$(suffix) {
		display:-ms-flexbox;
		display:flex;
		-ms-flex-pack:distribute;
		justify-content:space-around;
		-ms-flex-wrap:nowrap;
		flex-wrap:nowrap;
		margin-left:-112px;
	}

	$(selector)$(suffix) $(selector)__that {
		-ms-flex-preferred-size:50%;
		flex-basis:50%;
		margin-left:56px;
		padding-left:56px;
	}

	$(selector)$(suffix) $(selector)__that:not(:first-child) {
		border-left:1px solid rgba(0, 0, 0, 0.1);
	}
}

@mixin that-or-that .c-that-or-that;

@media(--md) {
	@mixin that-or-that .c-that-or-that, \@md;
}