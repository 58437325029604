.o-buffered-vertical {
	margin-left:-calc(var(--o-grid-gutter) / 2);
	@media(--md) {
		margin-left:-var(--o-grid-gutter);
	}
}

.o-buffered-vertical > * {
	padding-left:calc(var(--o-grid-gutter) / 2);
	@media(--md) {
		padding-left:var(--o-grid-gutter);
	}
}

.o-buffered-vertical--half {
	margin-left:-calc(var(--o-grid-gutter) / 4);
	@media(--md) {
		margin-left:-calc(var(--o-grid-gutter) / 2);
	}
}

.o-buffered-vertical--half > * {
	padding-left:calc(var(--o-grid-gutter) / 4);
	@media(--md) {
		padding-left:calc(var(--o-grid-gutter) / 2);
	}
}