.c-definition-list {
	@mixin gradient-box;
	padding: 0 20px;
}

.c-definition-list.c-definition-list--inverted-box {
	@mixin gradient-box-inverted;
}

.c-definition-list__term[class] {
	@mixin text-style-mu;
	padding: var(--spacing) 0 0 var(--spacing);
	word-wrap: break-word;
	overflow-wrap: break-word;
}

.c-definition-list__term--big[class] {
	padding: var(--spacing-sm) 0 0 var(--spacing-sm);
}

.c-definition-list__description[class] {
	border-bottom: 1px solid var(--color-light-grey);
	padding: 0 var(--spacing-sm) var(--spacing) 8px;
	margin-bottom: 0;
}

.c-definition-list__description:last-of-type {
	border-bottom: none;
}

.c-definition-list__description--big[class] {
	font-weight: bold;
	padding-bottom: var(--spacing-sm);
}

.c-definition-list__link,
.c-definition-list__link:visited {
	color: var(--color-grass);
}

.c-definition-list__comment[class] {
	@mixin text-style-epsilon;
	font-style: italic;
	color: var(--color-battleship-grey);
	margin-top: var(--spacing);
	padding: 0 0 var(--spacing) 8px;
}

/* stylelint-disable */
.c-definition-list.c-definition-list--as-item {
	@mixin reset-gradient-box;
}
/* stylelint-enable */

.c-definition-list.c-definition-list--as-item .c-definition-list__term[class] {
	padding: 0;
	border: 0 none;
}

.c-definition-list.c-definition-list--as-item .c-definition-list__description[class] {
	padding: 0 0 var(--spacing);
	border: 0 none;
	word-break: break-word;
}

@media (--sm) {
	.c-definition-list__comment[class] {
		padding-left: 24px;
	}
}

@media (--md) {
	.c-definition-list__term[class] {
		border-bottom: 1px solid var(--color-light-grey);
		padding-left: var(--spacing-sm);
		padding-bottom: var(--spacing);
	}

	.c-definition-list__term[class]:last-of-type {
		border-bottom: none;
	}

	.c-definition-list__term--big[class] {
		padding-bottom: var(--spacing-sm);
	}

	.c-definition-list__description[class] {
		padding: var(--spacing) var(--spacing-sm) var(--spacing) var(--spacing-sm);
	}

	.c-definition-list__description--big[class] {
		padding-top: var(--spacing-sm);
	}

	.c-definition-list.c-definition-list--as-item .c-definition-list__term[class] {
		padding-bottom: var(--spacing-xs);
		padding-right: var(--spacing-xs);
		-webkit-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;
	}

	.c-definition-list.c-definition-list--as-item .c-definition-list__description[class] {
		padding-bottom: var(--spacing-xs);
	}

	.c-definition-list__comment[class] {
		padding-left: 34px;
	}
}
