/* =============================================================================
 * Main navigation
============================================================================= */
.c-main-navigation {
	background-color:var(--color-grass);
	border-top-color:color(var(--color-grass) alpha(30%));
}

.c-main-navigation::after {
	background-color:color(var(--color-grass) alpha(30%));
}

.c-main-navigation__link--selected::after,
.c-main-navigation__link--expanded::after {
	background-color:var(--color-grass);
}


.c-main-navigation__link--expanded::after {
	z-index:17;
}