/* =============================================================================
 * Media
 *
 * Remember, any change must be made in all variants of the object.
============================================================================= */
.o-media {
	display:-ms-flexbox;
	display:flex;
}

.o-media .icon-external-link + .c-icon--right {
	display:none;
}

.o-media--middle {
	-ms-flex-align:center;
	align-items:center;
}

.o-media--inline {
	display:-ms-inline-flexbox;
	display:inline-flex;
}

.o-media--inline .o-media__primary {
	-ms-flex:0 auto;
	flex:0 auto;
}

.o-media--inline .o-media__primary .c-icon {
	vertical-align:bottom;
}

.o-media__primary {
	-ms-flex:1;
	flex:1;
}

.o-media__secondary {
	margin-right:24px;
}

.o-media__secondary--tight {
	margin-right:12px;
}

.o-media__secondary--no-pad {
	margin-right:0;
}

.o-media--reversed > .o-media__secondary {
	margin-right:0;
	margin-left:24px;
}

.o-media--reversed > .o-media__secondary--tight {
	margin-left:12px;
}

/*
 * This is bad, o-media should not know of any child elements.
 * Apply this on img separatly using an object instead.
 */
.o-media__secondary img {
	max-width:none;
}

@media(--s) {
	.o-media .o-media__secondary--tight\@s {
		margin-right:12px;
	}

	.o-media.o-media--reversed > .o-media__secondary--tight\@s {
		margin-left:12px;
	}
}

/*
 * @md
 */
@media(--md) {
	.o-media\@md {
		display:-ms-flexbox;
		display:flex;
	}

	.o-media\@md.o-media--middle {
		-ms-flex-align:center;
		align-items:center;
	}

	.o-media\@md .o-media__secondary {
		margin-right:24px;
	}

	.o-media\@md .o-media__secondary--tight {
		margin-right:12px;
	}

	.o-media\@md.o-media--reversed > .o-media__secondary {
		margin-right:0;
		margin-left:24px;
	}

	.o-media\@md.o-media--reversed > .o-media__secondary--tight {
		margin-left:12px;
	}

	/*
	* This is bad, o-media should not know of any child elements.
	* Apply this on img separatly using an object instead.
	*/

	.o-media\@md .o-media__secondary img {
		max-width:none;
	}
}

/*
 * @lg
 */
@media(--lg) {
	.o-media\@lg {
		display:-ms-flexbox;
		display:flex;
	}

	.o-media\@lg.o-media--middle {
		-ms-flex-align:center;
		align-items:center;
	}

	.o-media\@lg .o-media__secondary {
		margin-right:24px;
	}

	.o-media\@lg .o-media__secondary--tight {
		margin-right:12px;
	}

	.o-media\@lg.o-media--reversed > .o-media__secondary {
		margin-right:0;
		margin-left:24px;
	}

	.o-media\@lg.o-media--reversed > .o-media__secondary--tight {
		margin-left:12px;
	}

	/*
	* This is bad, o-media should not know of any child elements.
	* Apply this on img separatly using an object instead.
	*/

	.o-media\@lg .o-media__secondary img {
		max-width:none;
	}
}

/*
 * @xl
 */
@media(--xl) {
	.o-media\@xl {
		display:-ms-flexbox;
		display:flex;
	}

	.o-media\@xl.o-media--middle {
		-ms-flex-align:center;
		align-items:center;
	}

	.o-media\@xl .o-media__secondary {
		margin-right:24px;
	}

	.o-media\@xl .o-media__secondary--tight {
		margin-right:12px;
	}

	.o-media\@xl.o-media--reversed > .o-media__secondary {
		margin-right:0;
		margin-left:24px;
	}

	.o-media\@xl.o-media--reversed > .o-media__secondary--tight {
		margin-left:12px;
	}

	/*
	* This is bad, o-media should not know of any child elements.
	* Apply this on img separatly using an object instead.
	*/

	.o-media\@xl .o-media__secondary img {
		max-width:none;
	}
}
