/* =============================================================================
 * 1. Fix for IE11 flexbox image scaling bug:
		  https://github.com/philipwalton/flexbugs/issues/75#issuecomment-161800607
============================================================================= */
.o-page-section {
	min-height:1px; /* [1] */
}

.o-page-section {
	margin-bottom:48px;
}

.o-page-section--none,
.o-page-section--none\@sm {
	margin-bottom:0;
}

.o-page-section[class] > *:last-child {
	margin-bottom:0px;
}

@media(--md) {
	.o-page-section {
		margin-bottom:72px;
	}

	.o-page-section--none\@md {
		margin-bottom:0;
	}
}
