.c-byline-details {
	@mixin text-style-epsilon;
	color: var(--color-battleship-grey);
}

.c-byline-details::after {
	content: "";
	clear: both;
	display: table;
}

.c-byline-details__term {
	float: left;
	margin-right: 6px;
}

.c-byline-details--stacked .c-byline-details__description {
	float: none;
}

@media (--md) {
	.c-byline-details__description {
		float: left;
		margin-right: 6px;
	}
}

