/* Header */

.mypagesstart .mypagesstart__header{
	display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mypagesstart .mypagesstart__header__title{
	display:flex;
	width:100%;
	font-size:3rem;
	margin-bottom: var(--spacing-s);
	font-family: var(---type-family-default);
}

.mypagesstart .mypagesstart__header__title h1{
	height: auto;
	padding-left:1rem;
	font-size: 1em;
	display:inline-block;
	line-height: 1.1em;
	align-self: center;
	margin: 0;
	word-wrap: break-word;
}

/* Company info */

.mypagesstart .c-company-card{
	max-height: 160px;
}

/* Header image */
.mypagesstart .mypagesstart__header__title picture, .mypagesstart .mypagesstart__header__title picture img{
	height: 100%;
	width: 100%; 
	border-radius: 100%;
	display: block; 
}
.mypagesstart .mypagesstart__header__title picture{
	height: 1.5em;
	width: 1.5em;
	margin-top: 0.1em;
	flex-grow: 0;
	flex-shrink: 0;
}

/* Header Menu */

.mypagesstart__navigation{
	width: 100%;
	display: inline-block;
}
.mypagesstart__navigation ul{
	width: auto;
	display: inline-block;
	flex-direction: row;
	align-content: flex-start;
	border-bottom: 2px solid gray; 
}
.mypagesstart__navigation ul li{
	margin-right: var(--spacing-m);
	display:inline-block;
	padding-bottom: var(--spacing);
}
