.cookie-bar__content {
	padding:var(--spacing-sm) 0 var(--spacing-sm);
}

@media(--md) {
	.cookie-bar__content {
		@mixin buffer-box;
	}

	.cookie-bar__content p.s-text {
		margin-bottom:0;
	}

	.cookie-bar__content .c-button {
		float:right;
	}
}

.cookie-bar__content {
	padding:var(--spacing-sm) 0 var(--spacing-sm);
}

@media(--md) {
	.cookie-bar__content {
		@mixin buffer-box;
	}

	.cookie-bar__content p.s-text {
		margin-bottom:0;
	}

	.cookie-bar__content .c-button {
		float:right;
	}
}