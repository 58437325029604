.member-proof {
	padding:0 5em;
}

.member-proof .c-intro__heading {
	color:var(--color-grass);
	font-size:5em;
}

.member-proof .c-intro__preamble {
	color:#757575;
	font-size:1em;
}

.member-proof .c-intro__preamble::before {
	margin:0 auto 3em;
}

.member-proof .c-logo__image,
.member-proof .c-logo__text {
	display:block;
	margin:0;
	text-align:center;
}

.member-proof .s-text {
	font-size:1.2em;
}

.member-proof .c-logo__image,
.member-proof .c-logo__image > svg {
	width:150px;
	height:150px;
	margin:0 auto;
}

.member-proof .c-logo__text {
	color:var(--color-grass);
}

.member-proof .c-footer {
	background-color:#fff;
	color:#000;
	font-style:italic;
	text-align:center;
}

@media(--below-md) {
	.member-proof .c-logo {
		display:block !important;
	}	
}

@media print {
	.member-proof .c-footer {
		display:block !important;
	}
}