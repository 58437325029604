.c-mobile-search {
	color:#fff;
	display:block;
	text-align:center;
	padding-bottom:var(--spacing-s);
	padding-top:var(--spacing-s);
	@mixin width-limiter;
}

.c-mobile-search.c-mobile-search--active {
	background:rgb(169, 220, 146);
}

.c-mobile-search .c-search-form__icon {
	position:static;
}

.c-mobile-search .c-search-form__icon svg {
	fill:#fff;
}