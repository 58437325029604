.lSSlideOuter .lSPager.lSGallery li {
    opacity: .5;
    -webkit-transition: opacity .6s;
    transition: opacity .6s;
}

.lSSlideOuter .lSPager.lSGallery li.active, 
.lSSlideOuter .lSPager.lSGallery li:hover {
    border-radius: 0;
    opacity: 1;
}

.c-teaser__video-description { 
    position: absolute;
    bottom: 0;
    padding: 1.5em 2em;
}

.c-teaser__video-time { 
    background-color:var(--color-white);
    color:var(--color-grass);
    display: inline-block;
    font-weight: 700;
    margin-bottom: .5em;
    padding: .25em 1em;
}

.c-teaser__video-heading, 
.c-teaser__video-text { 
    color:var(--color-white);
}

.lSGallery .c-teaser__video-heading {
    position: absolute;
    bottom: 0;
    padding: 1em;
}

.c-icon-arrow-right {
    background:var(--color-white);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 60px;
    bottom: 0;
    margin: auto;
    -webkit-box-shadow: 0 0 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.c-icon-arrow-right svg {
    color:var(--color-grass);
}

@media(--below-md) {
    .c-teaser__video-description, 
    .lSGallery .c-teaser__video-heading { 
        display: none;
    }
}