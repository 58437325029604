.c-contact-card__img {
	display:block;
	width:156px;
	height:156px;
}

.c-contact-card__header:not(:last-child) {
	margin-bottom:0.706em;
}

.c-contact-card__description {
	font-style:italic;
	font-family:var(--type-family-secondary);
}

/* for small c-contact-cards */
@media(--md) {
	[data-size~="lg:3"] .c-contact-card .o-media {
		-ms-flex-direction:column;
		flex-direction:column;
	}

	[data-size~="lg:3"] .c-contact-card .c-card__content {
		width:156px;
		margin:0 auto;
		padding:1.412em 0.5em;
		text-align:center;
	}

	[data-size~="lg:3"] .c-contact-card__img {
		margin:1.412em auto 0;
	}
}

