/* Box */
.c-info-box {
	border:1px solid var(--color-prussian-blue);
	border-left:4px solid var(--color-prussian-blue);
	@mixin gradient-box;
	margin-bottom:24px;
	@mixin text-style-epsilon;
	background-color:var(--color-white);
	padding-right:48px;
	padding-bottom:16px;
	position:relative;
}
.c-info-box--important {
	border-color:#D7CD3C;
}
.c-info-box--very-important {
	border-color:#BE5F2D;
}
/* --- */
/* Box as aside */
aside.c-info-box {
	@media(--sm) {
		width:50%;
		float:right;
		margin-left:24px;
	}
}
/* --- */
/* Heading with background */
.c-info-box[class] h1 {
	@mixin text-style-zeta;
	text-transform:uppercase;
	font-weight:bold;
	color:var(--color-white);
	background-color:var(--color-prussian-blue);
	display:inline-block;
	vertical-align:top;
	padding:4px;
	position:relative;
}
.c-info-box[class] h1::after {
	content:"";
	position:absolute;
	left:100%;
	top:0;
	background-color:var(--color-prussian-blue);
	border-bottom:12px solid var(--color-white);
    border-left:9px solid transparent;
    border-top:12px solid var(--color-prussian-blue);
}
.c-info-box--important[class] h1 {
	color:var(--color-black);
}
.c-info-box--important[class] h1::after {
	background-color:#D7CD3C;
	border-top-color:#D7CD3C;
}
.c-info-box--very-important[class] h1::after {
	background-color:#BE5F2D;
	border-top-color:#BE5F2D;
}
/* --- */
/* Lists */
.c-info-box[class] ul {
	padding-left:48px;
}
.c-info-box[class] ol {
	padding-left:24px;
}
.c-info-box--default[class] ul li::before {
	background-color:var(--color-prussian-blue);
}
.c-info-box--important[class] h1,
.c-info-box--important[class] ul li::before {
	background-color:#D7CD3C;
}
.c-info-box--very-important[class] h1,
.c-info-box--very-important[class] ul li::before {
	background-color:#BE5F2D;
}
/* --- */
/* Content */
.c-info-box[class] *:not(h1):not(span) {
	margin-bottom:12px;
}
.c-info-box > * {
	padding-left:48px;
}
.c-info-box h1 + *:not(h1),
.c-info-box > :first-child:not(h1)  {
	margin-top:12px;
}
/* --- */
/* Icon */
.c-info-box h1 + *::before,
.c-info-box > :first-child:not(h1)::before {
	position:absolute;
	left:0;
	display:inline-block;
	content:'';
	width:48px;
	height:24px;
	background-repeat:no-repeat;
	background-position-x:center;
	vertical-align:top;
}
.c-info-box h1 + ul::before,
.c-info-box ul:first-of-type::before {
	display:block;
	position:absolute;
	left:0;
}
.c-info-box--default[class] h1 + *::before,
.c-info-box--default[class] > :first-child:not(h1)::before {
	background-image:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZyBzdHJva2Utd2lkdGg9IjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAsIDApIj48Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIxMSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDA1Mjc1IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciI+PC9jaXJjbGU+PGxpbmUgZGF0YS1jb2xvcj0iY29sb3ItMiIgeDE9IjEyIiB5MT0iMTEiIHgyPSIxMiIgeTI9IjE3IiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDUyNzUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWpvaW49Im1pdGVyIj48L2xpbmU+PGNpcmNsZSBkYXRhLWNvbG9yPSJjb2xvci0yIiBkYXRhLXN0cm9rZT0ibm9uZSIgY3g9IjEyIiBjeT0iNyIgcj0iMSIgZmlsbD0iIzAwNTI3NSI+PC9jaXJjbGU+PC9nPjwvc3ZnPg==);
}
.c-info-box--important[class] h1 + *::before,
.c-info-box--important[class] > :first-child:not(h1)::before {
	background-image:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZyBzdHJva2Utd2lkdGg9IjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAsIDApIj48cG9seWdvbiBmaWxsPSJub25lIiBzdHJva2U9IiNkN2NkM2MiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBwb2ludHM9IjIsMjIgMTIsMyAyMiwyMiAKCSIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciI+PC9wb2x5Z29uPgo8bGluZSBkYXRhLWNvbG9yPSJjb2xvci0yIiBmaWxsPSJub25lIiBzdHJva2U9IiNkN2NkM2MiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiB4MT0iMTIiIHkxPSIxMSIgeDI9IjEyIiB5Mj0iMTUiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiPjwvbGluZT4KPGNpcmNsZSBkYXRhLWNvbG9yPSJjb2xvci0yIiBkYXRhLXN0cm9rZT0ibm9uZSIgZmlsbD0iI2Q3Y2QzYyIgY3g9IjEyIiBjeT0iMTgiIHI9IjEiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiPjwvY2lyY2xlPjwvZz48L3N2Zz4=);
	/*OLD #e6a139 background-image:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZyBzdHJva2Utd2lkdGg9IjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAsIDApIj48cG9seWdvbiBmaWxsPSJub25lIiBzdHJva2U9IiNlNmExMzkiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBwb2ludHM9IjIsMjIgMTIsMyAyMiwyMiAiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiPjwvcG9seWdvbj48bGluZSBkYXRhLWNvbG9yPSJjb2xvci0yIiBmaWxsPSJub25lIiBzdHJva2U9IiNlNmExMzkiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiB4MT0iMTIiIHkxPSIxMSIgeDI9IjEyIiB5Mj0iMTUiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiPjwvbGluZT48Y2lyY2xlIGRhdGEtY29sb3I9ImNvbG9yLTIiIGRhdGEtc3Ryb2tlPSJub25lIiBmaWxsPSIjZTZhMTM5IiBjeD0iMTIiIGN5PSIxOCIgcj0iMSIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+PC9jaXJjbGU+PC9nPjwvc3ZnPg==);*/
}
.c-info-box--very-important[class] h1 + *::before,
.c-info-box--very-important[class] > :first-child:not(h1)::before {
	background-image:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZyBzdHJva2Utd2lkdGg9IjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAsIDApIj48cG9seWdvbiBmaWxsPSJub25lIiBzdHJva2U9IiNiZTVmMmQiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBwb2ludHM9IjIsMjIgMTIsMyAyMiwyMiAKCSIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciI+PC9wb2x5Z29uPgo8bGluZSBkYXRhLWNvbG9yPSJjb2xvci0yIiBmaWxsPSJub25lIiBzdHJva2U9IiNiZTVmMmQiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiB4MT0iMTIiIHkxPSIxMSIgeDI9IjEyIiB5Mj0iMTUiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiPjwvbGluZT4KPGNpcmNsZSBkYXRhLWNvbG9yPSJjb2xvci0yIiBkYXRhLXN0cm9rZT0ibm9uZSIgZmlsbD0iI2JlNWYyZCIgY3g9IjEyIiBjeT0iMTgiIHI9IjEiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiPjwvY2lyY2xlPjwvZz48L3N2Zz4=);
	/* OLD #b84840 background-image:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZyBzdHJva2Utd2lkdGg9IjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAsIDApIj48cG9seWdvbiBmaWxsPSJub25lIiBzdHJva2U9IiNiODQ4NDAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBwb2ludHM9IjIsMjIgMTIsMyAyMiwyMiAiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiPjwvcG9seWdvbj48bGluZSBkYXRhLWNvbG9yPSJjb2xvci0yIiBmaWxsPSJub25lIiBzdHJva2U9IiNiODQ4NDAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiB4MT0iMTIiIHkxPSIxMSIgeDI9IjEyIiB5Mj0iMTUiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiPjwvbGluZT48Y2lyY2xlIGRhdGEtY29sb3I9ImNvbG9yLTIiIGRhdGEtc3Ryb2tlPSJub25lIiBmaWxsPSIjYjg0ODQwIiBjeD0iMTIiIGN5PSIxOCIgcj0iMSIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+PC9jaXJjbGU+PC9nPjwvc3ZnPg==); */
}
/* --- */