.c-faq-question {
	padding-left:21px;
	text-indent:-21px;
}

@media(--md) {
	.c-intro__right-content {
		margin-top:0;
	}

	.c-faq-button {
		text-align:right;
	}
}