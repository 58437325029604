.c-notify-pill {
    display: inline-block;
    background-color: #F5A623;
    border-radius: 10px;
    cursor: default;
    text-align: center;
    white-space: nowrap;
}
.c-notify-pill span {
	font-size:12px;
	padding:10px;
	font-weight:bold;
	position:relative;
	top:-1px;
}

@media(--below-md) {
	.c-notify-pill {
		text-overflow:ellipsis;
		white-space:nowrap;
		width:80%;
		overflow:hidden;
	}
}

@media(--s) {
	.c-notify-pill {
		text-overflow:ellipsis;
		white-space:nowrap;
		width:auto;
		overflow:hidden;
	}
}