/**
 * The group of choice buttons
 */
.c-choice-buttons {
	display: flex;
	flex-direction: column;
}

.c-choice-buttons .c-choice-button:not(:last-child) {
	margin-bottom: 8px;
}

/**
 * A single choice buttons
 */
.c-choice-button {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: 51px; /* make sure that every choice has equal height even if they have meta information or not */
}

.c-choice-button__title {
	font-size: 15px;
	line-height: 1.6;
	color: var(--color-black);

	@media(--md) {
		font-size: 17px;
		line-height: 1.41;
	}

}

.c-choice-button__title a {
	color: inherit;
}

.c-choice-button__subtitle {
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	margin: 3px 0 0;
}

.c-choice-button__content {
	width: 100%;
	position: relative;
}

.c-choice-button__meta-list {
	display: flex;
	flex-direction: row;
	padding-top: 2px;
}

.c-choice-button__meta-item {
	display: inline-block;
	font-size: 13px;
	line-height: 1.38;
	margin: 0 0 2px;
	color: var(--color-main-label, rgb(116, 117, 117));

	@media(--md) {
		font-size: 15px;
		line-height: 1.6;
	}

}

.c-choice-button__meta-item:not(:last-child) {
	margin-right: 12px;
}

.c-choice-button__meta-item strong {
	font-weight: 700;
	color: var(--color-black);
}

.c-choice-button__icon {
	min-width: 24px;
	min-height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 4px;
}

/**
 * Overrides of checkbox
 */

.c-choice-button__checkbox {
	margin-right: 12px;

	@media(--md) {
		margin-right: 20px;
	}

}

.c-choice-button__checkbox .c-checkbox {
	margin-bottom: 0;
}

.c-choice-button .c-checkbox__label {
	width: 32px;
	height: 32px;
	padding: 0;
}

/* Hide input label */
.c-choice-button__checkbox .c-checkbox__label span {
	display: none;
}
