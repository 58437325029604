.c-teaser {
	@mixin gradient-box;
	padding:0 20px;
	height:100%;
}

.c-teaser__right-content[class] {
	padding:var(--spacing-m) var(--spacing-sm) var(--spacing-l);
}

.c-teaser__left-content[class] {
	padding-left:0;
	padding-right:0;
}

.c-teaser__text h2:first-of-type {
	@mixin trailing-dash var(--color-turtle-green), 48px;
}

.c-teaser__button {
	margin-top:var(--spacing-m);
}

.c-teaser__media {
	height:100%;
	-ms-flex:1;
	flex:1;
	-ms-flex:1 1 auto;
	flex:1 1 auto;
	-webkit-flex:1;
}

.c-teaser__video {
	overflow:auto;
	position:relative;
	padding-bottom:56.25%; /* 16:9 */
	height:0;
}

.c-teaser__image,
.c-teaser__video iframe {
	width:100%;
}

.c-teaser__video iframe {
	position:absolute;
	top:0;
	left:0;
	height:100%;
}

.thumbnail {
	content:"";
	cursor:pointer;
	width:100%;
	height:100%;
	position:absolute;
	overflow:hidden;
	z-index:1;
}

.thumbnail span {
	padding:12px 0;
	color:#fff;
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	margin:auto;
	height:50px;
	width:190px;
	border-radius:var(--spacing-ml);
	background:var(--color-grass);
	box-shadow:0 0 0 6px rgb(211, 236, 200);
	transition:all 200ms ease-in;
	text-align:center;
	font-weight:bold;
}

.thumbnail span::before {
	content:"";
	margin-right:15px;
	display:inline-block;
	width:0;
	height:0;
	border-top:12px solid transparent;
	border-left:20px solid #fff;
	border-bottom:12px solid transparent;
	vertical-align:bottom;
}

.thumbnail:hover span {
	box-shadow:none;
	text-decoration:none;
}

@media(--md) {
	.c-teaser__left-content[class] {
		padding-right:0;
	}

	.c-teaser__right-content[class] {
		padding-top:0;
		padding-left:var(--spacing-m);
		padding-right:var(--spacing-m);
	}
}

@media(--lg) {
	.c-teaser__right-content[class] {
		padding-left:var(--spacing-l);
		padding-right:var(--spacing-l);
	}
}

@media(--xl) {
	.c-teaser__right-content[class] {
		padding-left:var(--spacing-xxl);
		padding-right:var(--spacing-xxl);
	}
}
