.c-modal {
	position:fixed;
	z-index:9998;
	top:0;
	left:0;
	width:100vw;
	height:100vh;
	background-color:rgba(0, 0, 0, 0.5);
	transition:opacity 0.3s ease;
	text-align:left;
}
.c-modal__container {
	transition:all 0.3s ease;
	position:relative;
	top:150px;
	width:60%;
	max-width:960px;
	margin:auto;
	@media(--sm) {
		width:95%;
	}
}
.c-modal__content {
	padding:50px;
}
.c-modal__transition-enter {
	opacity:0;
}
.c-modal__transition-leave-active {
	opacity:0;
}
.c-modal__transition-enter .c-modal__container,
.c-modal__transition-leave-active .c-modal__container {
	transform:scale(2);
}

@media(--s) {
	.c-modal__container {
		width:100%;
	}
	.c-modal__content {
		padding:20px;
	}
}