.c-placeholder {
	@mixin text-style-reset;
	border:1px dashed var(--color-grass);
	display:-ms-flexbox;
	display:flex;
	-ms-flex-pack:center;
	justify-content:center;
	-ms-flex-align:center;
	align-items:center;
	font-style:italic;
	padding:var(--spacing-sm);
	width:100%;
}

.c-placeholder__text {
	@mixin text-style-reset;
}
