.scroll-to-top {
	background:rgba(75, 169, 37, 0.5);
	border:solid 1px #fff;
	color:#fff;
	cursor:pointer;
	display:none;
	padding:0.5rem;
	position:fixed;
	bottom:20px;
	right:20px;
	transform:rotateX(180deg);
	z-index:99;
}

.scroll-to-top:hover {
	background:rgb(75, 169, 37);
}