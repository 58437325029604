.c-sub-navigation__item--selected > a {
	color:var(--color-grass);
}

.c-sub-navigation__item--selected::before {
	border-color:var(--color-grass);
}

.c-icon--haschildren.c-icon,
.c-sub-navigation__item.c-icon {
	vertical-align:baseline;
}

