.c-product-card {
	flex: auto;
	margin-bottom:48px;
	background-color:#fff;
	box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.05);
}

.c-product-card__content {
	padding:0 36px 36px;
}

.c-product-card__content li {
	display:-ms-flexbox;
	display:flex;
	margin-bottom:15px;
	font-weight:700;
	line-height:1.2;
}

.c-product-card__content .c-button--round {
	width:2.352em;
	height:2.352em;
}

.c-product-card .c-icon--checkbox {
	width:27px;
	height:18px;
	overflow:hidden;
	margin-right:5px;
	color:#4ba925;
}

.c-product-card .c-icon--checkbox > svg {
	max-height:none;
}

.c-product-card hr {
	background-color:rgb(213, 214, 210);
	margin:1.5em 0;
}

.c-product-card__image {
	width:100%;
	max-width:none;
	height:auto;
}

.c-product-card__heading {
	position:relative;
	margin:calc(-1.5em - 2 * 15px - 1px) 20px 0;
	padding:15px;
	background:#fff;
	font-weight:700;
	font-size:21px;
	line-height:1.5;
}

.c-product-card__heading::after {
	display:block;
	width:50px;
	height:1px;
	margin-top:15px;
	background:#4ba925;
	content:"";
}

.c-product-card__read-more {
	font-family:"Freight", serif;
	font-style:italic;
}