.c-facet {
	margin-bottom:60px;
}

.c-facet__item {
	display:block;
	padding:12px 36px;
}

.c-facet__item.c-facet__item--selected {
	background-color:var(--color-grass);
	color:var(--color-white);
}

.c-facet__item:first-of-type {
	padding-top:0px;
}

.c-facet__item.c-facet__item--selected:first-of-type {
	padding-top:12px;
}
