.c-step-by-step-guide__step {
	background-color:var(--color-white);
	@mixin gradient-box;
	padding:1em;
	@media(--md) {
		padding:1.9em 2.118em 2.118em;
	}
}
.c-step-by-step-guide__header {
	position:relative;
	margin-bottom:1.5em;
}

.c-step-by-step-guide__header::after {
	content:"";
	position:absolute;
	bottom:-0.4em;
	left:0;
	width:2.824em;
	height:1px;
	background-color:var(--color-turtle-green);
}

.c-step-by-step-guide__step-number {
	font-weight:bold;
	font-size:0.706em;
	line-height:2;
	letter-spacing:1.5px;
	text-transform:uppercase;
	color:var(--color-turtle-green);
}

.c-step-by-step-guide__step-heading[class] {
	margin-bottom:0.294em;
	font-size:1.2em;
	line-height:1.2;
	font-weight:bold;
}
.c-step-by-step-guide__step-heading[class]:after {
	content: "";
    position: absolute;
    bottom: -0.4em;
    left: 0;
    width: 2.824em;
    height: 1px;
    background-color: rgb(108, 192, 74);
}

.c-step-by-step-guide__step-content {
	margin:24px 0;
}

.c-step-by-step-guide__step-options {
	@media(--sm) {
		display:flex;
	}
}

.c-step-by-step-guide__step-options button,
.c-step-by-step-guide__step-options a.c-button {
	flex:1 1 auto;
	@media(--s) {
		display:block;
		width:100%;
		margin:24px 0;
	}
}

.c-step-by-step-guide__step-options[class] button:hover,
.c-step-by-step-guide__step-options[class] button.selected,
.c-step-by-step-guide__step-options[class] a.c-button:hover {
	background-color:var(--color-turtle-green);
	color:var(--color-white);
	border-color:var(--color-white);
	box-shadow:none;
}