.c-megamenu {
	width:800px;
	color:var(--color-white);
	box-shadow:0 0 12px 0 rgba(0, 0, 0, 0.15);
}

.c-megamenu__header {
	border-bottom:solid 1px #fff;
	padding:var(--spacing-s) var(--spacing-m);
}

.c-megamenu__header-heading {
	@mixin text-style-reset;
	@mixin text-style-gamma;
	color:inherit;
}

.c-megamenu__header-link,
.c-megamenu__section-heading-link,
.c-megamenu__link {
	color:inherit;
}

.c-megamenu__main {
	column-count:3;
	column-gap:var(--spacing-m);
	padding:var(--spacing-sm) var(--spacing-m) var(--spacing-s);
}

.c-megamenu__section {
	page-break-inside:avoid;
	margin-bottom:var(--spacing-ml);
}

.c-megamenu__section-heading {
	@mixin text-style-delta;
	margin-bottom:var(--spacing-xs);
	font-weight:700; /* FIXME:should be a text-style? */
	color:var(--color-white);
}

.c-megamenu__link {
	@mixin text-style-epsilon;
	display:block;
	margin-bottom:var(--spacing-xs);
	line-height:1.2; /* FIXME:should be a text-style? */
}
