.c-image--cover {
	width:100%;
	height:100%;
	object-fit:cover;
	font-family:"object-fit:cover;";
}

.c-image--responsive {
	display:block;
	width:100%;
	height:auto;
}