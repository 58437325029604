.c-dropdown-variations {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.05);
  background: var(--color-white);
  width: 100%;
  transition: all 0.16s ease;
  position: relative;
  cursor: pointer;
}

.c-dropdown-variations::after {
  content: "";
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTUuMzc3cHgiIGhlaWdodD0iMjIuNjg0cHgiIHZpZXdCb3g9IjAgMCAxNS4zNzcgMjIuNjg0IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxNS4zNzcgMjIuNjg0IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwb2x5Z29uIGZpbGw9IiM1REIxNDYiIHBvaW50cz0iMS40Myw5LjI1OSAwLjAwOSw3Ljg1MSA3Ljc5MiwwIDE1LjM3Nyw3Ljg2MSAxMy45MzgsOS4yNSA3Ljc3MiwyLjg2IAkiLz4NCgk8cG9seWdvbiBmaWxsPSIjNURCMTQ2IiBwb2ludHM9IjcuNTg1LDIyLjY4NCAwLDE0LjgyMyAxLjQzOSwxMy40MzQgNy42MDQsMTkuODIzIDEzLjk0NywxMy40MjQgMTUuMzY3LDE0LjgzMyAJIi8+DQo8L2c+DQo8L3N2Zz4NCg==);
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  height: 97%;
  position: absolute;
  pointer-events: none;
  width: 38px;
  transition: var(--transition);
  top: 1%;
  right: 5px;
  background-color: var(--color-white);
}

.c-dropdown-variations__input {
  padding-left: 18px !important;
}

.c-dropdown-variations__content {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  max-height: 250px;
  overflow: auto;
  z-index: var(--z-index-popover);
  background-color: var(--color-white);
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.75);
}

.c-dropdown-variations__label {
  display: block;
  border: none;
  padding: 18px 0 0 12px;
  background-color: var(--color-white);
  height: 48px;
  width: 105%;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0px;
}

.c-dropdown-variations__list {
  position: relative;
}

.c-dropdown-variations__item {
  padding: 9px 0 9px 12px;
}

.c-dropdown-variations__item:hover {
  background-color: var(--color-white-grey);
}

.c-dropdown-variations__item-group-label {
  padding: 0.8em 1.2em 0.4em 1.2em;
  font-weight: 700;
  font-size: 0.625rem;
  text-transform: uppercase;
  background: rgb(211, 236, 200);
  cursor: initial;
}

.c-dropdown-variations__search {
  position: relative;
}

.c-dropdown-variations__search .c-icon {
  position: absolute;
  top: 15px;
  right: 13px;
  color: var(--color-cold-grey);
  cursor: initial;
}

.c-dropdown-variations__value-field {
  color: transparent !important;
  text-shadow: 0 0 0 #000;
  cursor: pointer;
  padding-left: 17px !important;
}

.c-dropdown-variations__value-field:focus {
  outline: none;
}
