.c-hamburger {
	color:var(--color-white);
	display:-ms-flexbox;
	display:flex;
	-ms-flex-align:center;
	align-items:center;
	padding:19px var(--spacing-sm) var(--spacing-s);
}

.c-hamburger:hover {
	cursor:pointer;
}

.c-hamburger:hover,
.c-hamburger:focus {
	color:var(--color-white);
}

.c-hamburger__wrapper {
	width:22px;
	margin-right:9px;
}

.c-hamburger__symbol {
	height:2px;
	display:block;
	background-color:var(--color-white);
	margin-bottom:6px;
}

.c-hamburger__text-close,
.c-hamburger.c-hamburger--active .c-hamburger__text {
	display:none;
}

.c-hamburger.c-hamburger--active .c-hamburger__text-close {
	display:block;
}

.c-hamburger.c-hamburger--active .c-hamburger__symbol-first {
	-ms-transform:rotate(-45deg);
	transform:rotate(-45deg);
	-ms-transform-origin:right;
	transform-origin:right;
}

.c-hamburger.c-hamburger--active .c-hamburger__symbol-second {
	width:0;
}

.c-hamburger.c-hamburger--active .c-hamburger__symbol-third {
	-ms-transform:rotate(45deg);
	transform:rotate(45deg);
	-ms-transform-origin:right;
	transform-origin:right;
}

.c-hamburger__symbol:last-child {
	margin-bottom:0;
}
