:root {
	--tooltip-triangle-size: 12px;
}

/**
 * The trigger of a tooltip
 */
.c-tooltip-trigger {
	white-space: nowrap;
	border-bottom: 1px dotted var(--color-battleship-grey);
	cursor: help;
}

/**
 * tooltip element (created using js)
 */
.c-tooltip {
	display: inline-block;
	position: absolute;
	z-index: 1000;
	padding: 8px;
	border-radius: 0;
	max-width: 200px;
	background-color: var(--color-turtle-green);
	box-shadow: 0px 1px 2px 0px rgb(0, 0, 0, 0.12);
	color: var(--color-white);
	font-size: 14px;
	line-height: 1.4;
	transition: opacity 0.2s, visibility 0.2s;
}

.c-tooltip a {
	color: inherit;
	text-decoration: underline;
}

@supports (clip-path: inset(50%)) {
	/* tooltip triangle */
	.c-tooltip::before {
		content: "";
		position: absolute;
		background-color: inherit;
		border: inherit;
		width: var(--tooltip-triangle-size);
		height: var(--tooltip-triangle-size);
		clip-path: polygon(0% 0%, 100% 100%, 100% 100%, 0% 100%);
	}
}

.c-tootip:not(.c-tooltip--sticky) {
	pointer-events: none;
}

.c-tooltip--lg {
	max-width: 350px;
	padding: 12px;
	font-size: 16px;
}

.c-tooltip--top::before,
.c-tooltip--bottom::before {
	left: calc(50% - var(--tooltip-triangle-size) / 2);
}

.c-tooltip--top::before {
	bottom: calc(var(--tooltip-triangle-size) * -0.5);
	transform: rotate(-45deg);
}

.c-tooltip--bottom::before {
	top: calc(var(--tooltip-triangle-size) * -0.5);
	transform: rotate(135deg);
}

.c-tooltip--left::before,
.c-tooltip--right::before {
	top: calc(50% - var(--tooltip-triangle-size) / 2);
}

.c-tooltip--left::before {
	right: calc(var(--tooltip-triangle-size) * -0.5);
	transform: rotate(-135deg);
}

.c-tooltip--right::before {
	left: calc(var(--tooltip-triangle-size) * -0.5);
	transform: rotate(45deg);
}

/* class used in js to hide the tooltip element before its top/left positions are set */
.c-tooltip--is-hidden {
	visibility: hidden;
	opacity: 0;
}
