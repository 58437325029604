.c-product-icon--usp {
	border-radius:50%/50%;
	box-shadow:0 0 0 2px rgb(198, 228, 185) inset;
	color:rgb(75, 169, 37);
	display:-ms-inline-flexbox;
	display:inline-flex;
	margin:0 auto 20px;
	width:3.529em;
	height:3.529em;
	-ms-flex-align:center;
	align-items:center;
	-ms-flex-pack:center;
	justify-content:center;
}


.c-product-icon--usp .c-button__icon {
	width:1.647em;
	height:1.647em;
}