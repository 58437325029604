.c-image-thumbnail {
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgb(0, 0, 0, 0.12);
	border-radius: 4px;
	width: 100px;
	height: 100px;
	padding: 8px;
	position: relative;
}

.c-image-thumbnail img {
	object-fit: cover;
	object-position: center;
	display: block;
	width: 100%;
	height: 100%;
}

.c-image-thumbnail__remove-btn {
	border: 0 none;
	background: var(--color-red);
	color: #fff;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -8px;
	right: -8px;
	padding: 2px;
	cursor: pointer;
}

.c-image-thumbnail__remove-btn svg {
	--size: 12px;
	font-size: var(--size);
	display: inline-block;
	color: inherit;
	fill: currentColor;
	height: var(--size);
	width: var(--size);
	line-height: 1;
	flex-shrink: 0;
	max-width: initial;
}
