/* =============================================================================
 * collapsible-content
 *
 * 1. Toggle button is hidden as default and shown if JS i enabled
============================================================================= */
.c-collapsible-content__toggled-area.collapsed {
	display:none;
}

.c-collapsible-content__toggle--generated {
	border-width:0;
	border-radius:0;
	text-decoration:none;
	@mixin text-style-mu;
	color:var(--color-grass);
	display:inline-block;
	margin:0 auto;
}

.c-collapsible-content__toggle[class] {
	display:none; /* [1] */
}