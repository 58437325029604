.c-print-layout__group > * {
	display:inline-block;
	vertical-align:top;
	white-space:normal;
}

.c-print-layout__group[data-columns="4"] > * {
	width:24.5%;
}

.c-print-layout__group[data-columns="3"] > * {
	width:32%;
}

.c-print-layout__group[data-columns="2"] > * {
	width:49%;
}

.c-print-layout__group > * > div {
	padding-bottom:0.4rem;
}

.c-print-layout__section {
	page-break-inside:avoid;
}

.c-print-layout__stand-alone {
	page-break-after:always;
}

.c-print-layout__page {
	width:21cm;
	height:29.7cm;
	padding:2cm;
	margin:1cm auto;
	border:1px #d3d3d3 solid;
	border-radius:5px;
	box-shadow:0 0 5px rgba(0, 0, 0, 0.1);
}

.c-print-layout__redacted {
	color: black;
	background-color: black;
}

@media print {
	.c-print-layout__page {
		margin:0;
		padding:0 2cm 1cm;
		border:initial;
		border-radius:initial;
		width:initial;
		height:auto;
		min-height:initial;
		box-shadow:initial;
	}

	* {
		overflow:visible !important;
	}

	body {
		-webkit-print-color-adjust:exact;
		font-size:9pt;
		line-height:1.2;
	}

	.o-grid,
	.o-grid > .o-grid__column {
		display:block !important;
	}

	.c-header,
	.c-mobile-logo,
	.c-tabs,
	.tab-chapters-mobile,
	.tab-chapters,
	.c-vote,
	.c-footer,
	.c-sub-navigation,
	.c-form,
	.scroll-to-top,
	.cookie-bar,
	.d-print-none {
		display:none !important;
	}

	/* editorialcontent */

	.o-editorial-content .s-text .preamble p,
	.o-editorial-content .s-text.preamble p {
		font-size:12pt;
		line-height:1.2;
	}

	.s-text ul,
	ul.s-text {
		list-style:inherit !important;
	}

	.s-text ul li::before,
	ul.s-text li::before {
		display:none;
	}
}

@page {
	margin:25mm 10mm;
}
