.c-button-group {
  display: flex;
  flex-direction: column;
	align-items: center;
}

.c-button-group--fullWidth .c-button {
	width: 100%;
}

@media(--md) {
	.c-button-group {
		flex-direction: row;
	}

	.c-button-group--fullWidth .c-button {
		width: auto;
	}

	.c-button-group .c-button--primary {
		margin-top: 0;
		margin-bottom: 0;
		width: auto;
	}

}

