.c-column-list__cta {
	color:var(--color-battleship-grey);
	text-decoration:underline;
}

.c-column-list__cta:hover,
.c-column-list__cta:focus {
	color:var(--color-battleship-grey);
	text-decoration:none;
}
