.c-media-list-item__category {
	display:inline-block;
	margin-bottom:0.2em;
	font-family:var(--type-family-secondary);
	font-size:1.059em;
	font-weight:500;
	font-style:italic;
	line-height:1.33;
	color:#757575;
}

.c-media-list-item__heading,
.c-media-list-item__heading > a {
	color:var(--color-black);
}

.c-media-list-item__heading {
	font-weight:bold;
	font-size:1em;
}

.c-media-list-item__heading > a {
	line-height:1.4;
	border-bottom:1px solid rgba(0, 0, 0, 0.15);
}

.c-media-list-item__heading > a:hover {
	border-bottom:1px solid rgb(116, 117, 117);
	text-decoration:none;
}

.c-media-list-item__body + * {
	margin-top:0.5em;
}

* + .c-media-list-item__body {
	margin-top:0.5em;
}

* + .c-media-list-item__footer {
	margin-top:0.5em;
}