.c-overflow-menu-trigger {
	border: 0 none;
	appearance: none;
	background: none;
	padding: 8px;
	margin: 0;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	cursor: pointer;
}

.c-overflow-menu-trigger svg {
	display: block;
}

.c-overflow-menu {
	--c-overflow-menu-vertical-gap: 4px;
	--c-overflow-menu-item-padding: 4px 8px;
	list-style: none;
	width: 220px;
	position: fixed;
	background: var(--color-white);
	box-shadow: 0px 1px 2px 0px rgb(0, 0, 0, 0.12);
	padding: 4px 0;
	border-radius: 2px;
	z-index: var(--z-index-popover);
	user-select: none;
	margin-top: var(--c-overflow-menu-vertical-gap);
	margin-bottom: var(--c-overflow-menu-vertical-gap);
	overflow: auto;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s 0.2s, opacity 0.2s;
	font-size: 16px;
}

.c-overflow-menu--is-visible {
	visibility: visible;
	opacity: 1;
	transition: visibility 0s, opacity 0.2s;
}

.c-overflow-menu--overlay {
	z-index: 100;
}

.c-overflow-menu__content {
	display: block;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: var(--c-overflow-menu-item-padding);
	color: var(--color-black);
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.c-overflow-menu__content:hover {
	background-color: var(--color-white-grey);
}

.c-overflow-menu__content:focus {
	outline: none;
	background-color: rgba(108, 192, 74, 0.1); /* turtle-green */
}

.c-overflow-menu__label {
	padding: var(--c-overflow-menu-item-padding);
	font-size: 14px;
	color: var(--color-battleship-grey);
}

.c-overflow-menu__separator {
	height: 1px;
	background-color: var(--color-light-grey);
	margin: var(--c-overflow-menu-item-padding);
}
