.c-card,
.c-card__content {
	-ms-flex:1;
	flex:1;
	-ms-flex:1 1 auto;
	flex:1 1 auto;
	-webkit-flex:1;
	height:100%;
}

.c-card {
	background-color:var(--color-white);
	@mixin gradient-box;
	
}

.c-card--plain {
	background-image:none;
	box-shadow:none;
}

.c-card--plain-green{
	background-image: none;
	box-shadow: 0 0 0 2px rgb(198, 228, 185);
}
.c-card__image{
	width: 100%;
	display: block;
	box-sizing: border-box;
	height: 0;
	padding: 25%;
	position: relative;
}
.c-card__image img, .c-card__image picture{
	object-fit: cover;
	object-position: center;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}

.c-card__heading,
.c-card__heading > a {
	color:var(--color-black);
}

.c-card__heading {
	margin-bottom:0.5em;
	font-weight:bold;
	font-size:1.294em;
}

.c-card__heading > a {
	line-height:1.4;
	border-bottom:1px solid rgba(0, 0, 0, 0.15);
}

.c-card__heading > a:hover {
	border-bottom:1px solid rgb(116, 117, 117);
	text-decoration:none;
}

.c-card__content {
	height:auto;
	padding:1em;
	@media(--md) {
		padding:1.9em 2.118em 2.118em;
	}
}

.c-card__content--small {
	padding-top:1em;
	@media(--md) {
		padding:2.118em 1.412em 1.412em;
	}
}

.c-card__content--tight {
	padding:1.412em;
}

.s-light .c-card {
	background-image:none;
}

.c-card__content .c-search-result {
	padding:0;
}
.c-card__meta{
	display:none;
}


/* GREEN */
.c-card--green, .c-card--green .c-card__content, .c-card--green .c-card__meta{
	background-color: #4ba925;
	color: white;
}
.c-card--green .c-card__heading, .c-card--green .c-card__content, .c-card--green .c-card__meta>.c-card__meta__category, .c-card--green .c-card__meta>.c-card__meta__date{
	color:white;
}
.c-card--green .c-card__heading,	
.c-card--green a{
	color:white;
}

.c-card--green .c-card__content > a {
	width: 100%;
	color: transparent;
}
.c-card--green{
	position: relative;
}

.c-card--green .c-card__content a, .c-card--green.c-card__content > a{
	color:white;
	text-decoration: underline;
	/* TODO: better way to show link on green background */
}


.c-card--center .c-card__heading, .c-card--center .c-card__content{
	text-align: center;
}
.c-card--center .c-card__heading:not(.c-card__heading + .c-card__content){
	margin-bottom: 0;
}

/*Fully clickable puff*/
.c-card--teaser{
	position: relative;
}
.c-card--teaser .c-card__content > a{
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	color: transparent;
}

/*INLINE*/

.c-card--inline{
	display:inline-block;
	flex:0;
	margin-top:20px;
}

.c-card.c-card--no-grow{
	flex-grow: 0;	
}


/* NEWS */

.c-card--news .c-card__meta{
	display:inline-block;
	box-sizing: border-box;
	width:100%;
	height: auto;
	padding: 0;
	padding-bottom: 0.5rem;
}
.c-card--news .c-card__meta__category{
	width: auto;
	max-width:50%;
	box-sizing: border-box;
	text-align: left;
	margin:0;
	float:left;
	text-transform: uppercase;
	font-weight: bold;
	overflow: hidden;
	text-overflow: ellipsis;
	
}
.c-card--news .c-card__meta__date{
	width: auto;
	max-width:50%;
	box-sizing: border-box;
	text-align: right;
	margin:0;
	color:var(--color-black);
	float:right;
	font-weight: 600;
}

.c-card--news .c-card__content > a{
	display:none;
}