.c-toggle-select__unselected {
	margin-top:36px;
	display:block;
}

.c-toggle-select__select {
	height:0px;
	display:none;
}

.c-toggle-select__toggle:checked ~ .c-toggle-select__select {
	height:75px;
	margin-top:36px;
	display:block;
}

.c-toggle-select__toggle:checked ~ .c-toggle-select__unselected {
	height:0px;
	display:none;
}
