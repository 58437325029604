.c-breadcrumbs {
	@mixin text-style-epsilon;
}

.c-breadcrumbs__current-page,
.c-breadcrumbs__link,
.c-breadcrumbs__link:hover,
.c-breadcrumbs__link:focus,
.c-breadcrumbs__separator {
	color:var(--color-battleship-grey);
}
