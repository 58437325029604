.c-paging {
	text-align:center;
	max-width:464px;
	margin:0 auto;
}

.c-paging::after {
	content:"";
	display:table;
	clear:both;
}

.c-paging__button {
	display:inline-block;
	padding:var(--spacing);
	color:var(--color-black);
}

.c-paging__button--previous {
	float:left;
}

.c-paging__button--next {
	float:right;
}

.c-paging__button--end {
	color:var(--color-cold-grey);
	cursor:not-allowed;
}

.c-paging__description {
	display:inline-block;
	word-spacing:var(--spacing-sm);
	color:var(--color-battleship-grey);
	margin-top:var(--spacing);
}

.c-paging__description strong {
	color:var(--color-black);
	font-weight:bold;
}