.card-list {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	flex-wrap: wrap;
	margin: 0;
	width: 100%;
}

.card-list > .c-card {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: calc(100% - 20px);
	margin: 10px;
	height: auto;
	
}


@media (--s) {
	.card-list > .c-card {
		flex-basis: 100%;
		margin-left: 0;
		margin-right: 0;
	}
	.card-list{
		padding-left:0;
		padding-right: 0;
	}
}

@media (--sm) {
	.card-list > .c-card {
		flex-basis: calc(50% - 20px);
	}
}

@media (--md){
	.card-list > .c-card {
		flex-basis: calc((100% / 3) - 20px);
	}
}

