.c-steps__number {
	position:relative;
	margin-bottom:12px;
	@media(--md) {
		margin-bottom:24px;
	}
}

.c-steps__number-text {
	position:relative;
	display:block;
	width:2.118em;
	height:2.118em;
	font-weight:bold;
	font-size:1em;
	line-height:2.118em;
	text-align:center;
	border-radius:50%/50%;
	background-color:var(--color-prussian-blue);
	color:#fff;
}

.c-steps__number::before {
	content:"";
	position:absolute;
	height:1px;
	top:0;
	right:0;
	left:0;
	bottom:0;
	margin:auto;
	background-color:var(--color-prussian-blue);
}