.o-editorial-content {
	max-width:680px;
}

.s-text img.t-float-left {
	margin-right: 1.5em;
	margin-bottom: 1.5em;
}

.s-text img.t-float-right {
	margin-left: 1.5em;
	margin-bottom: 1.5em;
}