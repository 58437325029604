.c-pop-in {
	color:rgb(255, 255, 255);
	display:block;
	max-width:400px;
	@media(--below-md) {
		width:100%;
		max-width:none;
		right:0;
		left:0 !important;
		margin-top:15px;
	}
}

.c-pop-in:focus {
	outline:none;
}

.c-pop-in-container {
	border-bottom:1px solid #fff;
	padding:24px;
}

.c-pop-in-topic,
.c-pop-in-preamble {
	font-family:var(--type-family-secondary);
	font-style:italic;
}

.c-pop-in-topic {
	color:rgba(255, 255, 255, 0.5);
	font-size:28px;
}

.c-pop-in h3 {
	margin-bottom:0.773em;
	font-weight:bold;
	font-size:1.294em;
	line-height:1.2;
}

.c-pop-in h3 span {
	border-bottom:1px solid rgba(255, 255, 255, 0.15);
}

.c-pop-in a {
	color:#fff;
	border-bottom:1px solid rgba(255, 255, 255, 0.15);
}

.c-pop-in .c-button.c-button--secondary,
.c-pop-in .c-button.c-button--secondary:visited {
	box-shadow:inset 0 0 0 2px #fff;
	color:#fff;
}

.c-pop-in .c-button.c-button--secondary:hover {
	color:var(--color-grass);
}

.c-pop-in .c-icon {
	margin-right:10px;
}

.c-pop-in ol {
	padding-top:18px;
}

.c-pop-in li {
	margin-bottom:10px;
}

/* IN INLOGGED */
