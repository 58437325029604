.c-asset-editor {
	margin-bottom:0;
}

.c-asset-editor__field {
	margin-bottom:var(--spacing-ml);
}

.c-asset-editor__field:last-child {
	margin-bottom:var(--spacing-xl);
}
