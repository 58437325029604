.c-text-editor__label {
	margin-bottom:12px;
	font-weight:700;
}

.c-text-editor__body {
	margin:12px 12px 0;
}

.c-text-editor__reset-button {
	margin-top:12px;
	float:right;
}
