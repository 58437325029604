.c-asset-list {
	@mixin gradient-box;
}

.c-asset-list__item {
	position:relative;
	padding:var(--spacing-sm);
	border-bottom:1px solid var(--color-light-grey);
}

.c-asset-list__item:last-of-type {
	border-bottom:none;
}

.c-asset-list__item-heading {
	@mixin text-style-gamma;
	margin-bottom:12px;
	display:inline-block;
	margin-right:6px;
}

.c-asset-list__item-title {
	color:var(--color-prussian-blue);
	text-decoration:underline;
}

.c-asset-list__item-details {
	display:table;
	clear:both;
	content:"";
}

.c-asset-list__action-button {
	position:absolute;
	right:var(--spacing-sm);
	top:calc(50% - 12px);
}

.c-asset-list--has-dl .c-definition-list--as-item {
	padding-right:100px; /* fix for remove btn */
}