.c-rect-input__textarea-label {
	@mixin text-style-reset;
	padding:12px 0;
	color: rgb(117, 117, 117);
}
.c-rect-input__textarea-label--before-invisible-textarea {
	@mixin text-style-reset;
	padding:12px 0;
	color: rgb(117, 117, 117);
}
.c-rect-input__invisible-textarea {
	color:transparent;
	background:transparent;
	box-shadow:none;
	border:0;
	margin:0;
	padding:0;
	outline:0;
	height:1px;
	resize:none;
}
.c-rect-input .quillWrapper {
	position:relative;
	top:-30px;
	margin-bottom: -30px;
}
.c-rect-input__input[readonly] {
	background-color:var(--color-light-grey);
	cursor:auto;
}
.ql-toolbar.ql-snow[class] {
	@mixin text-style-zeta;
	background-color:#edf6e9;
	border:1px solid rgb(108, 192, 74);
}
.ql-container.ql-snow[class] {
    border: none;
}
.ql-editor {
	@mixin text-style-zeta;
	background-color:white;
	border:1px solid rgb(108, 192, 74);
	border-top:0;
	@extend .s-text;
}
.ql-editor[class] ul,
.ql-editor[class] ol {
	margin-bottom:36px;
}
.ql-editor[class] ul>li:before {
	content:'';
}
.ql-editor[class] ul li:not(.ql-direction-rtl):before {
	margin-left:0;
}
.ql-editor[class] ul li:not(.ql-direction-rtl) {
	padding-left:0;
}
.ql-editor[class] p {
	margin-bottom:24px;
}
.ql-editor p,
.s-text--vue p {
	clear:both;
}
.ql-editor img,
.s-text--vue img
 {
	float:left;
	margin-right:20px;
	margin-bottom:20px;
}
