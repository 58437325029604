.o-flex {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;

	& > * {
		-ms-flex:0 1 auto;
		flex:0 1 auto;
	}

	&--inline {
		display:-ms-inline-flexbox;
		display:inline-flex;
	}

	&--row {
		-ms-flex-direction:row;
		flex-direction:row;
	}

	&--column {
		-ms-flex-direction:column;
		flex-direction:column;
	}

	&--nowrap {
		-ms-flex-wrap:nowrap;
		flex-wrap:nowrap;
	}

	&--space-between {
		-ms-flex-pack:justify;
		justify-content:space-between;
	}

	&--center {
		-ms-flex-pack:center;
		justify-content:center;
	}

	&--space-around {
		-ms-flex-pack:distribute;
		justify-content:space-around;
	}

	&--end {
		-ms-flex-pack:end;
		justify-content:flex-end;
	}

	&--items-start {
		-ms-flex-align:start;
		align-items:flex-start;
	}

	&--items-end {
		-ms-flex-align:end;
		align-items:flex-end;
	}

	&--items-center {
		-ms-flex-align:center;
		align-items:center;
	}

	&--items-basline {
		-ms-flex-align:baseline;
		align-items:baseline;
	}

	&--items-stretch {
		-ms-flex-align:stretch;
		align-items:stretch;
	}
}