/* =============================================================================
 * Main navigation
============================================================================= */
:root {
	--main-navigation-border-width:6px;
}

.c-main-navigation {
	position:relative;
	display:none;
	margin-bottom:var(--main-navigation-border-width);
	border-top-style:solid;
	border-top-width:var(--main-navigation-border-width);
	background-clip:padding-box;
}

.c-main-navigation::after {
	content:"";
	bottom:-var(--main-navigation-border-width);
	display:block;
	height:var(--main-navigation-border-width);
	left:0;
	position:absolute;
	width:100%;
	z-index:2;
}

.c-main-navigation a:hover {
	color:inherit;
	text-decoration:underline;
}

.c-main-navigation__item {
	position:relative;
}

.c-main-navigation__link {
	@mixin text-style-reset;
	color:var(--color-white);
	display:block;
	margin-right:36px;
	padding:var(--spacing-s) 0;
	position:relative;
	overflow:hidden;
}

.c-main-navigation .c-main-navigation__link:hover {
	color:var(--color-white);
	text-decoration:none;
}

.c-main-navigation__link::after {
	background:#fff;
	content:"";
	display:block;
	width:0;
	height:2px;
	margin-top:-2px;
	transition:width 0.3s;
}

.c-main-navigation__link:hover::after {
	width:100%;
}

.c-main-navigation__link--selected::after,
.c-main-navigation__link--expanded::after {
	content:"";
	bottom:-var(--main-navigation-border-width);
	display:block;
	height:var(--main-navigation-border-width);
	left:0;
	position:absolute;
	width:100%;
	z-index:4;
}

.c-main-navigation__arrow {
	display:inline-block;
	margin-left:var(--spacing-xs);
	transition:transform 0.2s ease;
}

.c-main-navigation__arrow .c-icon {
	height:10px;
	width:10px;
}

.c-main-navigation__link--expanded .c-main-navigation__arrow {
	transform:rotateX(180deg);
}

/* Dropdowns
---------------------------------------------------------------------------- */
.c-main-navigation__dropdown {
	display:none;
	position:absolute;
	top:calc(100% + var(--main-navigation-border-width));
	left:-var(--spacing-m);
	min-width:calc(100% + var(--spacing-m));
}

.c-main-navigation__dropdown--expanded {
	display:block;
	animation:main-navigation-dropdown 0.2s ease;
	z-index:16;
}

@keyframes main-navigation-dropdown {
	from {
		opacity:0;
	}

	to {
		opacity:1;
	}
}

@media(--md) {
	.c-main-navigation {
		display:block;
	}
}

@media(--xl) {
	.c-main-navigation__link {
		margin-right:48px;
	}
}
