.c-menu {
	min-width:100%;
	color:var(--color-white);
	box-shadow:0 0 12px 0 rgba(0, 0, 0, 0.15);
	padding:var(--spacing-sm) var(--spacing-m) var(--spacing-s);
}

.c-menu__link {
	@mixin text-style-epsilon;
	display:block;
	margin-bottom:var(--spacing-xs);
	color:inherit;
	line-height:1.2; /* FIXME:should be a text-style? */
}
