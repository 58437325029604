/* =============================================================================
 * Confirm dialog
============================================================================= */

/* The modal
 ---------------------------------------------------------------------------- */
.c-confirm {
	display:-ms-flexbox;
	display:flex;
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:1337;
	-ms-flex-pack:center;
	justify-content:center;
	-ms-flex-align:center;
	align-items:center;
	background:rgba(0, 0, 0, 0);
	transition:background 0.25s ease;
}

.c-confirm--appear {
	background:rgba(0, 0, 0, 0.5);
}


/* The message
 ---------------------------------------------------------------------------- */
.c-confirm__message {
	@mixin text-style-beta;
	max-width:580px;
	margin:24px;
	padding:36px;
	background:rgb(255, 255, 255);
	opacity:0;
	transition:opacity 0.25s ease, transform 0.25s ease;
	-ms-transform:scale(0.4);
	transform:scale(0.4);
}

.c-confirm__message--appear {
	opacity:1;
	-ms-transform:scale(1);
	transform:scale(1);
}

.c-confirm__buttons {
	text-align:right;
}