.o-buffered {
	margin-top:-calc(var(--o-grid-gutter) / 2);
	@media(--md) {
		margin-top:-var(--o-grid-gutter);
	}
}

.o-buffered > * {
	padding-top:calc(var(--o-grid-gutter) / 2);
	@media(--md) {
		padding-top:var(--o-grid-gutter);
	}
}

.o-buffered--half {
	margin-top:-calc(var(--o-grid-gutter) / 4);
	@media(--md) {
		margin-top:-calc(var(--o-grid-gutter) / 2);
	}
}

.o-buffered--half > * {
	padding-top:calc(var(--o-grid-gutter) / 4);
	@media(--md) {
		padding-top:calc(var(--o-grid-gutter) / 2);
	}
}