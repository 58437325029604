.s-system-bg {
	background-color:var(--color-white);
}

.s-system {
	margin-top:72px;
}

.s-system h1,
h1.s-system {
	@mixin text-style-alpha;
	margin-bottom:48px;
	color:var(--color-white);
}

.s-system h1,
h1.s-system,
.s-system h2,
h2.s-system,
.s-system h3,
h3.s-system,
.s-system h4,
h4.s-system,
.s-system h5,
h5.s-system,
.s-system h6,
h6.s-system,
.s-system ul,
ul.s-system,
.s-system ol,
ol.s-system,
.s-system dl,
dl.s-system,
.s-system blockquote,
blockquote.s-system,
.s-system p,
p.s-system,
.s-system address,
address.s-system,
.s-system hr,
hr.s-system,
.s-system table,
table.s-system,
.s-system fieldset,
fieldset.s-system,
.s-system figure,
figure.s-system,
.s-system pre,
pre.s-system {
	color:var(--color-black);
}

.s-system .preamble {
	@mixin leading-dash var(--color-light-grey-green);
}

.s-system a,
a.s-system {
	text-decoration:underline;
}

.s-system .preamble p,
p.s-system .preamble,
.s-system .preamble a {
	@mixin text-style-beta;
	color:var(--color-prussian-blue);
	margin-bottom:36px;
}

.s-system .preamble a:hover,
.s-system .preamble a:focus {
	color:var(--color-grass);
}

@media(--md) {
	.s-system {
		margin-top:108px;
	}
}
