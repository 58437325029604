/* mPopup box style */
.lightbox {
	display:none;
	position:fixed;
	z-index:100;
	left:0;
	top:0;
	width:100%;
	height:100%;
	overflow:auto;
	background-color:rgb(0, 0, 0);
	background-color:rgba(0, 0, 0, 0.4);
}

.lightbox-content {
	position:relative;
	background-color:#fff;
	margin:auto;
	top:25%;
	max-width:650px;
	box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.lightbox-main {
	text-align:center;
}

@keyframes animatetop {
	from {
		top:-50%;
		opacity:0;
	}

	to {
		top:50%;
		opacity:1;
	}
}

.lightbox-main-topic {
	@mixin trailing-dash var(--color-turtle-green), 48px;
	@mixin text-style-gamma;
	margin-bottom:var(--spacing-m);
}

.lightbox-main-topic::after {
	margin:24px auto 0;
}

.close-icon {
	color:#4ba925;
	font-size:2rem;
	position:absolute;
	top:0;
	right:0;
	text-align:center;
	width:50px;
	height:50px;
}

.close-icon:hover,
.close-icon:focus {
	text-decoration:none;
	cursor:pointer;
}