.c-filter-list form {
	margin-bottom:var(--spacing-sm);
}

.c-filter-list__section {
	padding:var(--spacing-sm);
	background-color:var(--color-white);
	@mixin gradient-box;
}

.c-filter-list__section + .c-filter-list__section {
	margin-top:var(--spacing-sm);
}

.c-filter-list__heading {
	@mixin text-style-delta;
	margin-bottom:var(--spacing-m);
	font-weight:bold;
}

@media(--md) {
	.filter-list-wrapper {
		-ms-flex-order:1;
		order:1;
	}

	.c-filter-list > .c-collapsible-content > .c-collapsible-content__toggle {
		display:none !important;
	}

	.c-filter-list > .c-collapsible-content > .c-collapsible-content__toggled-area.collapsed {
		display:block;
	}
}