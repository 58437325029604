.c-news-list{
	width: 100%;
}

.c-news-list .c-news-list__heading{
	color: var(--color-black);
	float:left;
	font-weight: bold;
	font-size: 2rem; 
	padding-left:0.5rem;
}

.c-news-list .c-news-list__more-link{
	float: right;
	padding-right: 0.5rem;
	padding-top:1rem;
	padding-right: 2rem;
}

.c-news-list .card-list{
	justify-content: flex-start;
}
.c-news-list .card-list>.c-card{
	flex-grow: 0;
	flex-shrink: 0;
}