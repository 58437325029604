.c-island {
	--padding: calc(var(--spacing) * 2);
	--padding-large: calc(var(--spacing) * 4);

	padding: var(--padding);
}

.c-island--large {
	padding: var(--padding-large);
}

@media(--lg) {
	.c-island--large\@lg {
		padding: var(--padding-large);
	}
}