.c-block-section {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	flex-direction:column;
	-ms-flex:1 1 auto;
	flex:1 1 auto;
	-webkit-flex:1;
	clear:both;
}

.c-block-section--centered .c-block-section__content {
	margin-right:auto;
	margin-left:auto;
	max-width:920px;
}

.c-block-section--centered .c-block-section__header,
.c-block-section--centered .c-block-section__preamble {
	text-align:center;
}

.c-block-section--centered .c-block-section__header::after {
	top:auto;
	right:0;
	bottom:-0.4em;
	left:0;
	margin:auto;
}

.c-block-section--centered .c-block-section__preamble {
	width:100%; /* fix for IE11 */
	max-width:680px;
	margin:0 auto;
}

.c-block-section--right .c-block-section__heading,
.c-block-section--right .c-block-section__preamble {
	text-align:right;
}

.c-block-section--right .c-block-section__header::after {
	right:0;
	left:auto;
}

.c-block-section__header {
	position:relative;
	margin-bottom:1.5em;
}

.c-block-section__footer {
	margin-top:0.5em;
}

.c-block-section__header::after {
	content:"";
	position:absolute;
	bottom:-0.4em;
	left:0;
	width:2.824em;
	height:1px;
	background-color:var(--color-turtle-green);
}

.c-block-section__topic-heading {
	font-weight:bold;
	font-size:0.706em;
	line-height:2;
	letter-spacing:1.5px;
	text-transform:uppercase;
	color:var(--color-battleship-grey);
}

.c-block-section__heading {
	margin-bottom:0.294em;
	font-size:2em;
	line-height:1.2;
	font-weight:bold;
}

.c-block-section__content {
	width:100%;
	height:100%;
}

.c-block-section__link {
	float:right;
	clear:right;
	font-size:0.882em;
	line-height:2;
}

.c-block-section__listitem {
	margin-top:0.706em;
}

/* EPI Edit:shadow on/off */

.c-block-section.no-box .c-card {
	box-shadow:none;
	background:transparent;
}

.c-block-section.no-box .c-card .c-card__content {
	padding:0;
}

/* Blocks in TinyMCE-editor */
li:not(.c-accordion__fold) .s-text .c-block-section {
	margin-bottom:var(--spacing-s);
	@media(--md) {
		margin-bottom:var(--spacing-m);
	}
}

li:not(.c-accordion__fold) .s-text .c-block-section ul {
	margin-left:-20px;
	margin-bottom:0;
}

li:not(.c-accordion__fold) .s-text .c-block-section ul li::before,
li:not(.c-accordion__fold) .c-block-section ul.s-text li::before {
	display:none;
}