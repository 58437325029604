/* =============================================================================
 * Add item
============================================================================= */
.c-add-item,
.c-remove-item {
	display:-ms-flexbox;
	display:flex;
	width:100%;
}

.c-add-item__field {
	-ms-flex-positive:2;
	flex-grow:2;
}

.c-add-item__button {
	text-align:left;
	margin-left:var(--spacing-s);
}
