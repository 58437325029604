@define-mixin quote $selector {
	$(selector) {
		font-family:var(--type-family-secondary);
		font-size:28px;
		line-height:1.1;
		color:var(--color-grass);
	}
}

@define-mixin borderedquote $selector{
	$(selector){
		font-family:var(--type-family-secondary);
		font-size:28px;
		line-height:1.1;
		color:var(--color-grass);
		border:solid #c6e4b9;
		border-width:1px 0;
		padding:var(--spacing-m) 0;		
	}
}

@mixin quote c-quoute;