.c-calendar-event__date {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	flex-direction:column;
	-ms-flex-pack:center;
	justify-content:center;
	-ms-flex-align:center;
	align-items:center;
	padding:0.545em;
	width:2.727em;
	height:3.273em;
	font-weight:bold;
	font-size:22px;
	color:var(--color-grass);
	background-color:var(--color-white);
	box-shadow:0 0 12px 0 rgba(0, 0, 0, 0.07);
}


.c-calendar-event__day {
	line-height:1;
}

.c-calendar-event__month {
	font-size:0.545em;
	text-transform:uppercase;
	letter-spacing:0.167em;
	line-height:2;
	white-space:nowrap;
}

* + .c-calendar-event__icon {
	margin-left:0.5em;
}

@media(--s) {
	.c-calendar-event__footer {
		display:-ms-flexbox;
		display:flex;
		-ms-flex-direction:column;
		flex-direction:column;
		-ms-flex-align:start;
		align-items:flex-start;
	}

	* + .c-calendar-event__icon {
		margin-left:0;
	}
}

.c-calendar-event__date {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	flex-direction:column;
	-ms-flex-pack:center;
	justify-content:center;
	-ms-flex-align:center;
	align-items:center;
	padding:0.545em;
	width:2.727em;
	height:3.273em;
	font-weight:bold;
	font-size:22px;
	color:var(--color-grass);
	background-color:var(--color-white);
	box-shadow:0 0 12px 0 rgba(0, 0, 0, 0.07);
}


.c-calendar-event__day {
	line-height:1;
}

.c-calendar-event__month {
	font-size:0.545em;
	text-transform:uppercase;
	letter-spacing:0.167em;
	line-height:2;
	white-space:nowrap;
}

* + .c-calendar-event__icon {
	margin-left:0.5em;
}

@media(--s) {
	.c-calendar-event__footer {
		display:-ms-flexbox;
		display:flex;
		-ms-flex-direction:column;
		flex-direction:column;
		-ms-flex-align:start;
		align-items:flex-start;
	}

	* + .c-calendar-event__icon {
		margin-left:0;
	}
}