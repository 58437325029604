.c-sub-navigation {
	padding:1.412em;
}

.c-sub-navigation__heading {
	margin-bottom:var(--spacing-s);
	font-weight:bold;
}

.c-sub-navigation__item {
	position:relative;
}

.c-sub-navigation__item:not(:first-child) {
	margin-top:0.706em;
}

.c-sub-navigation__item > a,
.c-sub-navigation__heading .c-sub-navigation__item {
	color:var(--color-black);
}

.c-sub-navigation__item--selected > a,
.c-sub-navigation__item > a:hover,
.c-sub-navigation__heading .c-sub-navigation__item--selected {
	color:var(--color-grass);
}

.c-sub-navigation__item--selected::before {
	content:"";
	position:absolute;
	left:-1.412em;
	height:1.412em;
	border-left:4px solid var(--color-grass);
}