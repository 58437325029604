.c-link-list,
.s-text .c-link-list {
	margin-top:-0.706em;
	margin-left:0;
}

.c-link-list__item {
	margin-top:0.706em;
}

.s-text .c-link-list__item::before {
	background:none;
	width:0;
	height:0;
}

.c-link-list__icon {
	margin-right:1em;
}