.c-list-item {
	display:table-cell;
	padding: 8px 16px;
	vertical-align:middle;
}
.c-list-item__label {
	font-weight:400;
	font-size: 14px;
    line-height: 1.143;
	color:var(--color-battleship-grey);
}

.c-list-item--loading .c-list-item__label {
	display: inline-block;
	width: 5em;
	height: 1em;
	background-color: var(--color-battleship-grey);
	opacity: .05;
}

.c-list-item--loading .c-list-item__value {
    width: 10em;
    background-color: rgb(117, 117, 117, .1);
    height: 1em;
}

*:nth-child(2n+1) > .c-list-item--loading .c-list-item__value {
	width: 14em;
}

*:nth-child(3n+1) > .c-list-item--loading .c-list-item__value {
	width: 12em;
}

.c-list-item--loading .c-list-item__action {
	display: inline-block;
    width: 5em;
    height: 1em;
	background-color: var(--color-grass);
	opacity: .1;
}

@media(--s) {
	.c-list-item {
		display:block;
	}
}