.c-horizontal-divider {
	border-top:1px solid var(--color-light-grey-green);
	margin:71px -21px 60px -21px;
}

@media(--md) {
	.c-horizontal-divider {
		margin-right:-56px;
		margin-left:-58px;
	}
}

@media(--lg) {
	.c-horizontal-divider {
		margin-left:-61px;
		margin-right:-60px;
	}
}

@media(--xl) {
	.c-horizontal-divider {
		margin-left:-89px;
		margin-right:-80px;
	}
}
