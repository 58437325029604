.c-number {
	display:inline-block;
	font-size:28px;
	line-height:0.87;
	float:left;
	position:relative;
	margin:0 24px 0 16px;
	color:var(--color-white);
	z-index:1;
}

.c-number::before {
	background-color:var(--color-grass);
	border-radius:100%;
	content:"";
	position:absolute;
	top:calc(50% - 24px);
	left:calc(50% - 24px);
	height:48px;
	width:48px;
	z-index:-1;
}
