/* =============================================================================
 * Mobile Navigation
============================================================================= */
.c-mobile-navigation\@lg {
	display:none;
	position:-webkit-sticky;
	position:sticky;
	top:0;
	z-index:1;
}

.c-mobile-navigation {
	background-color:var(--color-white);
	margin-top:60px;
}

.c-mobile-navigation__list {
	width:100%;
}

.c-mobile-navigation__link {
	color:var(--color-black);
	display:block;
	padding:0 12px;
	margin:24px 0;
	text-decoration:none;
}

.c-mobile-navigation__list:not(.c-mobile-navigation__list--level-1) {
	margin-bottom:24px;
	margin-left:24px;
}

.c-mobile-navigation.c-mobile-navigation--active {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	flex-direction:column;
	-ms-flex-pack:justify;
	justify-content:space-between;
	position:fixed;
	top:72px;
	width:100%;
	height:calc(100vh - 60px);
	overflow:auto;
	-webkit-overflow-scrolling:touch;
	padding-top:var(--spacing);
	z-index:99;
}


body.taken .c-header .c-message {
	display:none !important;
}

/* Selected page link
----------------------------------------------------------------------------- */
.c-mobile-navigation__item--selected > .c-mobile-navigation__link {
	border-left-width:4px;
	border-left-style:solid;
}

.c-mobile-navigation__list.c-mobile-navigation__list--level-2 {
	width:90%;
}

/* Navigation footer
----------------------------------------------------------------------------- */
.c-mobile-navigation__footer {
	color:var(--color-black);
	margin-bottom:36px;
}

@media(--md) {
	.c-mobile-navigation\@lg {
		display:inherit;
	}

	.c-mobile-navigation\@sm {
		display:none;
	}
}
