.c-table-list {
	@mixin gradient-box;
}

.c-table-list__header-item {
	@mixin text-style-mu;
	padding:var(--spacing) var(--spacing-ml) var(--spacing) 0;
	border-bottom:1px solid var(--color-light-grey);
	text-align:left;
}

.c-table-list__header-item--first {
	width:100%;
	padding-left:var(--spacing-sm);
}

.c-table-list__header-item--last {
	padding-left:var(--spacing-ml);
	padding-right:var(--spacing-xl);
	min-width:55px;
}

.c-table-list__item {
	border-bottom:1px solid var(--color-light-grey);
	padding-bottom:var(--spacing-sm);
	padding-top:var(--spacing-sm);
	padding-right:var(--spacing-ml);
	white-space:nowrap;
}

.c-table-list__item--first {
	font-weight:bold;
	padding-left:var(--spacing-sm);
	width:100%;
}

.c-table-list__item--last {
	padding-left:var(--spacing-ml);
	padding-right:var(--spacing-xl);
}

.c-table-list__link {
	text-decoration:underline;
}

.c-table-list__name {
	margin-bottom:var(--spacing);
}

.c-table-list__term,
.c-table-list__description {
	@mixin text-style-epsilon;

}

.c-table-list .c-article-teaser__heading {
	font-size:1em;
}

/* Styling for when table is wider than parent (same styling as the responsive one) */
.c-table-list--compact.c-table-list,
.c-table-list--compact.c-table-list thead,
.c-table-list--compact.c-table-list tbody,
.c-table-list--compact.c-table-list th,
.c-table-list--compact.c-table-list td,
.c-table-list--compact.c-table-list tr {
	display:block;
}

/* Hide table headers (but not display:none;, for accessibility) */
.c-table-list--compact.c-table-list thead tr {
	position:absolute;
	top:-9999px;
	left:-9999px;
}

.c-table-list--compact .c-table-list__row {
	border-bottom:1px solid var(--color-grass);
}

.c-table-list--compact .c-table-list__row:last-child {
	border-bottom:none;
}

.c-table-list--compact .c-table-list__item {
	position:relative;
	padding-left:50%;
	white-space:normal;
}

.c-table-list--compact .c-table-list__item::before {
	@mixin text-style-mu;
	content:attr(data-title);
	position:absolute;
	top:var(--spacing-sm);
	left:var(--spacing-s);
	word-break:break-all;
}

@media(--s) {
	.c-table-list,
	.c-table-list thead,
	.c-table-list tbody,
	.c-table-list th,
	.c-table-list td,
	.c-table-list tr {
		display:block;
	}

	/* Hide table headers (but not display:none;, for accessibility) */

	.c-table-list thead tr {
		position:absolute;
		top:-9999px;
		left:-9999px;
	}

	.c-table-list__row {
		border-bottom:1px solid var(--color-grass);
	}

	.c-table-list__row:last-child {
		border-bottom:none;
	}

	.c-table-list__item,
	.c-table-list__item--last,
	.c-table-list--compact .c-table-list__item {
		position:relative;
		padding:var(--spacing-s);
	}

	.c-table-list__item::before,
	.c-table-list--compact .c-table-list__item::before {
		@mixin text-style-mu;
		content:attr(data-title);
		display:block;
		position:relative;
		top:auto;
		left:auto;
		word-break:break-all;
		margin-bottom:var(--spacing);
	}

}
