.o-trunc-text {
	overflow:hidden;
	position:relative;
	line-height:1.41em;
	max-height:4.23em;
	text-align:justify;
	margin-right:-0.5em;
	padding-right:1em;
}

.o-trunc-text::before {
	content:"...";
	position:absolute;
	right:4px;
	bottom:0px;
}

.o-trunc-text::after {
	content:"";
	position:absolute;
	right:0;
	width:1em;
	height:1em;
	margin-top:0.2em;
	background:var(--color-main-background);
}