.c-search-result {
	max-width:680px;
	list-style:none;
}

.c-search-result__title a {
	font-size:17px;
	font-weight:700;
	line-height:24px;
	margin-bottom:5px;
}

.c-search-result__description {
	font-family:Freight, serif;
	font-style:italic;
	margin-bottom:10px;
}

.c-search-result__meta {
	margin:5px 0;
	color:rgb(117, 117, 117);
}

.c-search-result__date {
	font-family:Freight, serif;
	font-style:italic;
}

.c-search-result__date--updated::before {
	display:inline-block;
	width:1px;
	height:1em;
	margin:0 6px 0 2px;
	background:rgb(117, 117, 117);
	vertical-align:middle;
	content:"";
}

.c-search-result__tags {
	margin-top:5px;
	font-weight:bold;
	font-size:13px;
	text-transform:uppercase;
	letter-spacing:0.5px;
}

.c-search-result__tags > * + *::before {
	display:inline-block;
	width:1px;
	height:1em;
	margin:0 6px 0 2px;
	background:rgb(117, 117, 117);
	vertical-align:middle;
	content:"";
}

.c-search-result__body > p {
	display:inline;
	vertical-align:middle;
}

.c-search-result__category {
	color:rgb(117, 117, 117);
}

.c-search-result__category::after {
	background-color:rgb(169, 220, 146);
	border-radius:50%;
	content:"";
	display:inline-block;
	width:8px;
	height:8px;
	margin:0 5px;
	position:relative;
	top:-1px;
}

/* locked */
.c-search-result__locked .c-search-result__title *,
.c-search-result__business .c-search-result__title * {
	color:#000;
}

.c-search-result.c-search-result__locked .c-icon-link__text {
	color:#000;
}

.c-icon-locked__icon {
	margin-bottom:2px;
	vertical-align:middle;
}

.c-icon + .c-icon {
	margin-left:0.3em;
}

.c-icon-external-link {
	vertical-align:baseline;
	margin-left:5px;
}

.c-search-result__locked .jsExpanderIcon {
	display:none;
}

/* documents */
.c-icon-link__tag {
	color:rgb(117, 117, 117);
	font-size:10px;
	font-weight:bold;
	padding-left:10px;
	text-transform:uppercase;
}

.c-search-result .c-icon-link__text {
	@mixin text-style-reset;
	font-weight:bold;
	margin:0;
	vertical-align:bottom;
	color:var(--color-grass);
}

.c-search-result .c-icon-link__type::before {
	content:" — ";
	padding-right:0.3em;
	padding-left:0.3em;
	color:#8b8b8b;
}

.c-search-result__title .c-search-result__bestbet {
	color:var(--color-battleship-grey);
	font-family:var(--type-family-secondary);
	font-style: italic;
	font-weight: 400;
	padding: 0 .1em 0 .5em;
	white-space: nowrap;
}