@define-mixin image-text $selector {
	$(selector) {
		padding-top:1em;
		font-style:italic;
		font-size:0.882em;
		line-height:1.6;
		color:var(--color-black);
	}
}

@mixin image-text .c-image-text;