.c-section__header {
	margin-bottom:var(--spacing-m);
}

.c-section__button,
.c-section__button:visited {
	margin-top:var(--spacing-m);
}

@media(--md) {
	.c-section__header {
		margin-bottom:0;
	}
}
