
.c-calendar-list .c-calendar-list__heading{
	color: var(--color-black);
	float:left;
	font-weight: bold;
	font-size: 2rem; 
	padding-left:0.5rem;
}

.c-calendar-list .c-calendar-list__more-link{
	float: right;
	padding-right: 0.5rem;
	padding-top:1rem;
	padding-right: 2rem;
}

.c-calendar-list.card-list{
	justify-content:flex-start;
}
.c-calendar-list.card-list .c-card{
	flex-grow: 0;
}
.c-calendar-list.card-list .c-card .c-media-list-item{
	display: flex;
	flex-direction: column-reverse;
}


.c-calendar-list .c-calendar-event .c-media-list-item__body{
	display:none;
}
.c-calendar-list .c-calendar-event{ 
	padding:var(--spacing-sm);
} 